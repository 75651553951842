import React from 'react'
import {hasOrderDetail} from './hoc/has_order_detail'
import { observer } from 'mobx-react'
import {Helmet} from 'react-helmet'
import PlateCostBreakdown from './plate_cost_breakdown'
import Currency from './currency'
import {Link} from 'react-router-dom'

export const CheckoutFailed = ({order, plate}) => {
  return <>
  <Helmet>
    <title>{plate.text} Order Failed</title>
  </Helmet>
  <main className="max-w-screen-xl mx-auto lg:px-6 lg:py-12">
    <div id="#content" className="flex flex-col lg:grid lg:grid-cols-6 lg:gap-24">
      <article className="px-3 py-6 lg:p-0 lg:col-span-3">

        <div className="flex flex-col items-center">
              <div className="font-medium text-xs uppercase tracking-wide text-gray-500">Order #{ order.order_id }</div>
              <p className="text-lg text-gray-700">Your order has failed <Link to={'/checkout/'+plate.id}>click here to try again</Link></p>
        </div>

      </article>
    </div>
  </main>
</>
}

export default observer(hasOrderDetail(CheckoutFailed))
