
import React from 'react'
import {ASSIGNMENT_FEE} from '../settings'


export const CheckoutConfirm = ({onStageCompleted, onSelectStage, plate, values}) =>
  <>
    <div className="space-y-2 pb-6">
      <h1 className="font-medium text-2xl lg:text-3xl">Confirm &amp; Pay</h1>
      <p className="text-gray-700 lg:text-lg">Confirm your purchase details before proceeding to our secure payment provider.</p>
    </div>
    <div className="flex flex-col border border-gray-400 rounded divide-y">
      <div className="flex flex-col p-6 space-y-4">
        <div className="flex flex-row items-baseline">
          <h3 className="font-medium text-gray-800">Contact details</h3>
          <a onClick={() => onSelectStage(0)} className="font-medium text-sm text-blue-700 ml-auto">Change</a>
        </div>
        <div className="">
          <div className="text-sm text-gray-700">{values['salutation']} {values['first_name']} {values['last_name']}</div>
          <div className="text-sm text-gray-700">{values['email']}</div>
          <div className="text-sm text-gray-700">{values['telephone']}</div>
          <div className="text-sm text-gray-700">{values['address1']} {values['address2']} {values['town']} {values['county']} {values['postcode']}</div>
        </div>
      </div>
      <div className="flex flex-col p-6 space-y-4">
        <div className="flex flex-row items-baseline">
          <h3 className="font-medium text-gray-800">Owner details</h3>
          <a onClick={() => onSelectStage(1)} className="font-medium text-sm text-blue-700 ml-auto">Change</a>
        </div>
        <div className="">
            <div className="text-sm text-gray-700">{values['owner_salutation']} {values['owner_first_name']} {values['owner_last_name']}</div>
          { values['assign_registration'] && 
            <div className="text-sm text-gray-700">Assigned by EasyReg (+&pound;{ASSIGNMENT_FEE})</div>
          }
          { !values['assign_registration'] && 
            <div className="text-sm text-gray-700">I'll transfer myself</div>
          }
        </div>
      </div>
    </div>
    <div className="lg:col-span-7">
      <div className="flex flex-col space-y-3 bg-gray-200 rounded p-3 lg:p-6"> 
        <button className="bg-blue-700 py-3 px-4 rounded inline-flex justify-center items-center hover:bg-blue-800 transition duration-300" onClick={onStageCompleted}>
          <span className="text-white tracking-wide font-bold">Proceed to Payment</span>
        </button>
        <div className="text-sm text-center text-gray-600">Secured by <span className="font-medium">GlobalPayments&trade;</span></div> 
      </div>
    </div>
  </>

export default CheckoutConfirm


