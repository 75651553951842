import React, { useContext, useState } from 'react'
import { RegistrationContext } from './registration_context'

export const FavouriteButton = ({plate, selected}) => {
  const store = useContext(RegistrationContext)
  const [hovered,setHovered] = useState(false)

  const clickFavourite = (ev) => {
    store.toggleFavouriteItem(plate)
    ev.stopPropagation()
  }

  return <button
          className={"h-10 w-10 flex justify-center items-center text-gray-500 rounded-full hover:bg-gray-200 hover:text-gray-700 favourite " + (selected?"favourite-selected":"favourite-unselected")+" "+ (hovered?"favourite-hovered":"favourite-unhovered")}
          onClick={clickFavourite}
          onMouseEnter={()=>setHovered(true)}
          onMouseLeave={()=>setHovered(false)}
        >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current w-4 h-4">
        <path d="M12,21.844l-9.588-10A5.672,5.672,0,0,1,1.349,5.293h0a5.673,5.673,0,0,1,9.085-1.474L12,5.384l1.566-1.565a5.673,5.673,0,0,1,9.085,1.474h0a5.673,5.673,0,0,1-1.062,6.548Z"/>
      </svg>
    </button>
} 

export default FavouriteButton
