import React from 'react'
import {Helmet} from 'react-helmet'

export const  TermsAndConditions = () =>
  <>
    <Helmet>
      <title>EasyReg Terms &amp; Conditions</title>
    </Helmet>
    <main className="max-w-screen-xl px-3 py-6 mx-auto lg:px-6 lg:py-12 lg:grid lg:grid-cols-5 lg:gap-24">

      <article className="col-span-4 space-y-6">

        <section className="space-y-3">

          <h1 className="font-medium text-4xl">Terms &amp; Conditions</h1>

          <p className="text-gray-800">These Terms and Conditions set out both the terms of use of our website www.easy-reg.co.uk ("Site") and for purchase of number plates from Site. They apply in addition to our Privacy Policy which is also available to read on Site and which sets out how your personal data will be handled.</p>

          <p className="text-gray-800">Please read these Terms and Conditions carefully and ensure that you understand them before ordering any Products from Site.  You will be required to read and accept these Terms and Conditions when ordering Products.  If you do not agree to comply with and be bound by these Terms and Conditions, you will not be able to order Products through Site.  These Terms and Conditions, as well as any and all Contracts are in the English language only.</p>

        </section>

        <ol className="list-decimal list-inside space-y-6 lg:list-outside">
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Definitions and Interpretation</h2>
            <p className="text-gray-800">In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</p>

            <table className="text-gray-800">
              <tbody className="divide-y">
                <tr className="h-12">
                  <td className="font-medium">"Contract"</td>
                  <td>means a contract for the purchase and sale of Products, as explained in Clause 8;</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">"Order"</td>
                  <td>means your order for the Products;</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">"Order Confirmation"</td>
                  <td>means our acceptance and confirmation of your Order;</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">"Products"</td>
                  <td>means the car number plate (registrations)  which are to be provided by Us to you as specified on the Site (we do not currently sell the physical number plate); and</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">"We/us/our"</td>
                  <td>means Easy Reg Ltd registered office Flagship House, Reading Road North, Fleet, GU51 4WP United Kingdom, UK company no. 12698440.</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Information About Us</h2>
            <p className="text-gray-800">The Site is owned and operated by Easy Reg Ltd,  registered office Flagship House, Reading Road North, Fleet, GU51 4WP United Kingdom, UK company no. 12698440. Our VAT number is [[insert VAT number]].</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Access to and Use of Site</h2>
            <ol className="list-decimal text-gray-800 pl-6">
              <li>Access to the Site is free of charge.</li>
              <li>It is your responsibility to make any and all arrangements necessary in order to access the Site.</li>
              <li>Access to the Site is provided "as is" and on an "as available" basis.  We may alter, suspend or discontinue Site (or any part of it) at any time and without notice.  We will not be liable to you in any way if Site (or any part of it) is unavailable at any time and for any period.</li>
              <li>Use of the Site is subject to these terms and conditions.</li>
              <li>Information on our Site is protected by copyright and you may not commercialise or copy or screen scrape data from or otherwise commercially use any information on our Site whether protected by copyright or not.  The Site is for use by those wanting to buy a car number plate (registration). We are not responsible for any links on the Site to third party sites nor for those third party sites.</li>
              <li>Whilst we aim to ensure any number plates we advertise on our Site are available we cannot guarantee they are and we have no liability to you if they are not other than if you have ordered and paid we would give you a refund in such a case as your sole remedy.</li>
              <li>You must not hack or alter any details on our Site.</li>
            </ol>
          </li>
          <li className="space-y-2"><h2 className="font-medium text-2xl">Age Restrictions</h2>
            <p className="text-gray-800">Consumers may only purchase Products through Site if they are at least 18 years of age or if older the age of majority in the country in which they are resident. If you buy a number plate for someone under age 18 you must buy it in your own name and then give it to the intended recipient yourself.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Resale to Business and Consumer Customers</h2>
            <p className="text-gray-800">We welcome customers buying a number plate for their business and those buying as consumers.  We currently "buy" number plates (registrations) (the Products) from DVLA and resell them to you as a reseller and do not sell the physical number plate. When you order a number plate from our Site we then purchase this from DVLA and send you confirmation of your number plate. You need to obtain your own financing and have a credit or debit card accepted by our Site in order to buy Products from us.</p>
            <p className="text-gray-800">As the Products are bespoke to you even where buying as a consumer you have no right to cancel your Order (unless the Product supplies is not the number/letters which you ordered.   Consumers' rights to cancel without cause under the Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013 do not apply due to regulation 28(b) of such regulations as the Products are personalised. If you ask us to buy a Product for you from DVLA we then buy it from DVLA and have no resale value for it as it is specific to you.  We make no warranty or guarantee that buying from us is the cheapest way to buy the registration nor that you could not buy it direct from the DVLA website at the same or a different price.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Products and Location and DVLA Rules</h2>
            <p className="text-gray-800">Whilst we accept orders from customers outside the UK customers should do their own investigation as to how and where they can use a number plate outside the UK. We only "sell" UK car number plates (registrations) from the UK. You should also satisfy yourself about the ability to use the Product on any specific vehicle or otherwise.</p>
            <p className="text-gray-800">You must follow Department of Transport rules for registration numbers at all times.  For example the following guidance is issued by the Government:</p>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="space-y-2">
                <h3 className="font-medium text-lg">Renew or replace your private number</h3>
                <p className="text-gray-800">You must renew your right to use your private (personalised) number every 10 years if it is not being used on a vehicle. If you got your private number before 2015, you must renew it more often - check your V750 or V778 document.</p>
                <p className="text-gray-800">You'll permanently lose the right to use the number if you do not renew it before the expiry date. DVLA will not accept applications made after that date.</p>
              </li>
              <li className="space-y-2">
                <h3 className="font-medium text-lg">Renew your V750 certificate of entitlement or V778 retention document</h3>
                <p className="text-gray-800">You can apply to renew your V750 or V778 up to 28 days before it expires. Do not apply earlier than this or your application may be refused.</p>
                <p className="text-gray-800">You can renew it for up to 10 years, and it does not cost anything to do: <a className="font-medium text-blue-800 underline" href="https://www.gov.uk/personalised-vehicle-registration-numbers/renew-private-number-certificate">https://www.gov.uk/personalised-vehicle-registration-numbers/renew-private-number-certificate</a></p>
                <p className="text-gray-800">It is your responsibility to fulfil all legal requirements once we have completed your purchase of the Product.</p>
                <p className="text-gray-800">You must not change the "age" of a vehicle through your use of a personalised number plate.</p>
                <p className="text-gray-800">You should ensure the vehicle to which the registration will be attached is taxed and has the appropriate MOT certificate as required by law. This is your responsibility not ours.</p>
                <p className="text-gray-800">If you take a registration transferred on a certificate (we do not sell cars or physical number plates on our Site) then  we will be the original purchaser on the certificate. The registered keep of a vehicle controls the registration from when it is assigned to a vehicle.   We have no involved in the process of your applying a registration to a particular care or physical number plate and that is your responsibility. We simply buy the registrations from DVLA and resell them to you.   It is illegal to display a registration before the Department of Transport has not completed the transfer to the vehicle of which you are the registered keeper.  As we quote above certificates only last for up to 10 years.</p>
              </li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Products, Pricing and Availability</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">We make all reasonable efforts to ensure that all general descriptions of the Products available from Us correspond to the Products that will be provided to you. We warrant that the number plate registration you order will be the one we supply to you. However it is for you to ascertain if a letter or number such as "I" or "l" will adequately represent your name or other special word when put on a car number plate. Orders should be placed on the website and not by telephone to reduce the chance of errors.</li>
              <li className="text-gray-800">We neither represent nor warrant that all Products will be available at all times and cannot necessarily confirm availability until confirming your Order. Where you order but DVLA then tell us the plate is not available then we reserve the right to cancel your order and will provide you with a full fund. We will have no further liability in such a case. In addition DVLA may have wrongly listed a registration or otherwise made a mistake and we are not liable to you other than to refund you the price paid to us in such a case. All registrations and their transfer are subject to DVLA and other approval  and this includes rights of the Secretary of State to withdraw numbers at any point even years after purchase. If that happens to you we are not liable to refund you fees paid. Car registration numbers are not items of property and more than a limited company number might be so you will not acquire legal title to the registration. They are assigned to and may be withdrawn from vehicles rather than keepers by the Secretary of State for whom DVLA acts on his or her behalf as part of the registration and licensing process specified in law and as varied from time to time. Only the registered keeper of a vehicle in the UK may apply to or retain its registration number. </li>
              <li className="text-gray-800">We make all reasonable efforts to ensure that all prices shown on Site are correct at the time of going online.  We reserve the right to change prices and to add, alter, or remove special offers from time to time and as necessary.  Changes in price will not affect any Order that you have already placed other than where VAT rates change as set out in clause 7.6 below (if VAT is applicable to the Order).</li>
              <li className="text-gray-800">All prices are checked by Us when We process your Order.  In the unlikely event that We have shown incorrect pricing information, We will contact you in writing before proceeding with your Order to inform you of this and to ask you how you wish to proceed.  We will give you the option to purchase the Products at the correct price or to cancel your Order (or the affected part thereof).  We will not proceed with processing your Order until you respond.  If We do not receive a response from you within 7 days, We will treat your Order as cancelled and notify you of the same in writing.</li>
              <li className="text-gray-800">In the event that the price of Products you have ordered changes between your Order being placed and Our processing that Order and taking payment, you will be charged the price shown on Site at the time of placing your Order.</li>
              <li className="text-gray-800">All prices on Site include VAT.  If the VAT rate changes between your order being placed and Our taking payment, the amount of VAT payable will be automatically adjusted when taking payment.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Orders &ndash; How Contracts Are Formed</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">The Site will guide you through the ordering process.  Before submitting your Order to Us you will be given the opportunity to review your Order and amend any errors.  Check your Order carefully before submitting it. You have no rights to a plate until you have fully paid for it.</li>
              <li className="text-gray-800">No part of Site constitutes an offer capable of acceptance.  Your Order constitutes an offer that We may, at Our sole discretion, accept.  Our acknowledgement of receipt of your Order does not mean that We have accepted it.  Our acceptance is indicated by Our sending to you an Order Confirmation by email.  Only once We have sent you an Order Confirmation will there be a legally binding contract between Us and you (“the Contract”).</li>
              <li className="text-gray-800 space-y-3">Order Confirmations shall contain the following information:
                <ol className="list-decimal space-y-3 pl-6">
                  <li className="text-gray-800">Confirmation of the Products ordered  i.e. the number plate (registration);</li>
                  <li className="text-gray-800">Fully itemised pricing for the Products ordered including, where appropriate, taxes and other additional charges;</li>
                  <li className="text-gray-800">Any other relevant information.</li>
                </ol>
              </li>
              <li className="text-gray-800">If We, for any reason, do not accept or cannot fulfil your Order, no payment shall be taken under normal circumstances.  If We have taken payment any such sums will be refunded to you as soon as possible and in any event within  14 days. All refunds are made under these Terms & Conditions only to the card on which you placed the Order originally for money laundering legal reasons.</li>
              <li className="text-gray-800 space-y-3">We may cancel your Order at any time before We begin providing the Products in the following circumstances:
                <ol className="list-decimal space-y-3 pl-6">
                  <li className="text-gray-800">DVLA no longer have the plate available.; or</li>
                  <li className="text-gray-800">An event outside of Our control continues for more than 14 days as provided below (force majeure).</li>
                </ol>
              </li>
              <li className="text-gray-800">If We cancel your Order under sub-Clause 8.5 and We have taken payment any such sums will be refunded to you as soon as possible and in any event within 14 days.  If We cancel your Order, you will be informed by email.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Payment</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">Payment for the Products is by card as indicated on the Site. If you want to pay another way contact us in advance and we will see if we can agree the alternative method although we do not accept payment in cash in any circumstances.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Provision of the Products</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">We provide the Products with the warranty in clause 7.1 (that the plate will be the number you ordered). We do not guarantee delivery by a particular time as that depends on DVLA's confirmation of the registration to us but we will aim to resell the Product to you quickly once we have bought it from DVLA. We buy the Product in our name and we contract with DVLA and then resell it to you. We do not act as your or DVLA's agent.  We are not  responsible for any delays if an event outside of Our control occurs as under the force majeure clause below.</li>
              <li className="text-gray-800">If you do not pay Us for the Products we reserve the right not to supply them to you. Payment is made in advance by card. If for any reason you are late in paying us interest is due from you to Us on overdue sums at the statutory rate of 8% above bank base rate from time to time.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Problems with the Products and Your Legal Rights</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">If there is a problem with the Products contact Us as soon as is reasonably possible using email where the Products do not match what you ordered.</li>
              <li className="text-gray-800">If you are a consumer you have additional legal rights with respect to the purchase of goods and services.  For full details of your legal rights and guidance on exercising them, it is recommended that you contact your local Citizens Advice Bureau or Trading Standards Office.  As you are only buying a number plate (registration) from us it will only be where the Product is not the number you ordered or never becomes available to you that would lead to claims by you against Us.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Our Liability</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">We will be responsible for any foreseeable loss or damage that you may suffer as a result of Our breach of these Terms and Conditions or as a result of Our negligence. </li>
              <li className="text-gray-800">We only provide the registration, not physical number plates and we make no warranty that you can use the registration on a particular car on in a particular place nor that it will look like a word or name when you have it applied to a vehicle and as stated above we do not guarantee a registration number will never be withdrawn as that is in the discretion of the Secretary of State.</li>
              <li className="text-gray-800">We exclude our liability for all loss of profit, loss of business, interruption to business or for any loss of business opportunity except as provided in clause  and we cap all our liability at &pound;1000 or the sum you paid for the Products in dispute if higher, subject in all cases to clause 12.4 below.</li>
              <li className="text-gray-800">Nothing in these Terms and Conditions seeks to exclude or limit Our liability for death or personal injury caused by Our negligence (including that of Our employees, agents or sub-contractors) nor for fraud or fraudulent misrepresentation.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Events Outside of Our Control (Force Majeure)</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">We will not be liable for any failure or delay in performing Our obligations where that failure or delay results from any cause that is beyond Our reasonable control.  Such causes include, but are not limited to: power failure, internet service provider failure, industrial action by third parties, civil unrest, fire, explosion, flood, storms, earthquakes, subsidence, acts of terrorism, disease, pandemic, Government lockdowns,  acts of war, other governmental action, epidemic or other natural disaster, or any other event that is beyond Our reasonable control </li>
              <li className="text-gray-800">If any event described in this Clause 13 occurs that is likely  adversely to affect Our performance of any of Our obligations under these Terms and Conditions:-
                <ol className="list-decimal space-y-3 pl-6">
                  <li className="text-gray-800">We will inform you as soon as is reasonably possible;</li>
                  <li className="text-gray-800">Our obligations under these Terms and Conditions (and therefore the Contract) will be suspended and any time limits that We are bound by will be extended accordingly;</li>
                  <li className="text-gray-800">We will inform you when the event outside of Our control is over and provide details of any new dates, times or availability of Products as necessary;</li>
                  <li className="text-gray-800">If the event outside of Our control continues for more than 30 days We may cancel the Contract and inform you of the cancellation.  Any refunds due to you as a result of that cancellation will be paid to you as soon as is reasonably possible and in any event no later than 14 calendar days after the date on which We inform you of the cancellation;</li>
                  <li className="text-gray-800">If an event outside of Our control occurs and continues for more than 14 days and you wish to cancel the Contract as a result you should email us with full details of your order including any Order number.</li>
                  <li className="text-gray-800">Any refunds due to you as a result of such cancellation will be paid to you as soon as is reasonably possible and in any event no later than 14 calendar days after the date on which you inform Us that you wish to cancel.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Communication and Contact Details</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">If you wish to contact Us with general questions or complaints, you may contact Us:
                <ol className="list-decimal space-y-3 pl-6">
                  <li className="text-gray-800">By Email on info@easy-reg.co.uk</li>
                  <li className="text-gray-800">By Telephone from the UK on 0808 196 4560, or</li>
                  <li className="text-gray-800">By Post on Easy Reg Limited, Flagship House, Reading Road North, Fleet, GU51 4WP United Kingdom.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How We Use Your Personal Information (Data Protection)</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">We will only use your personal information as set out in Our Privacy Policy, available on our website.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Other Important Terms</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">We may assign Our obligations and rights under these Terms and Conditions (and under the Contract, as applicable) to a third party including without limitation where we sell the assets of our business.  If this occurs, you will be informed by Us in writing.  Your rights under these Terms and Conditions will not be affected and Our obligations under these Terms and Conditions will be transferred to the third party who will remain bound by them.</li>
              <li className="text-gray-800">You may not assign your obligations and rights under these Terms and Conditions (and under the Contract, as applicable.</li>
              <li className="text-gray-800">The Contract is between you and Us.  It is not intended to benefit any other person or third party in any way and no such person or party will be entitled to enforce any provision of these Terms and Conditions.</li>
              <li className="text-gray-800">If any of the provisions of these Terms and Conditions are found to be unlawful, invalid or otherwise unenforceable by any court or other authority, that / those provision(s) shall be deemed severed from the remainder of these Terms and Conditions.  The remainder of these Terms and Conditions shall be valid and enforceable.</li>
              <li className="text-gray-800">No failure or delay by Us in exercising any of Our rights under these Terms and Conditions means that We have waived that right, and no waiver by Us of a breach of any provision of these Terms and Conditions means that We will waive any subsequent breach of the same or any other provision.</li>
              <li className="text-gray-800">We may revise these Terms and Conditions from time to time in response to changes in relevant laws and other regulatory requirements or otherwise at our discretion but if you do not agree the new terms you can cancel any existing  Orders already placed by you and obtain a refund.</li>
            </ol>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Law and Jurisdiction</h2>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with the law of England.</li>
              <li className="text-gray-800">If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Sub-Clause 17.1 removes your rights as a consumer to rely on those provisions.</li>
              <li className="text-gray-800">If you are a consumer, any dispute, controversy, proceedings or claim between you and Us relating to these Terms and Conditions, or the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency. </li>
              <li className="text-gray-800">If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the non exclusive jurisdiction of the courts of England & Wales save that where you bring a claim against us it must be in the English courts. </li>
            </ol>
          </li>
        </ol>
        <p className="text-gray-800">Terms and Conditions Easy Reg Limited v1 October 2020</p>
      </article>

    </main>
  </>

export default TermsAndConditions
