import React, { useContext, Fragment, useEffect } from 'react'
import IFramePost from './components/iframe_post'


export const do_checkout = ({plate,values,store,popup,go_to_url}) => {
    const my_name = {
      'title': values.salutation,
      'first': values.first_name,
      'last': values.last_name,
    }
    let nominee = {
        "name": {
          'title': values.owner_salutation,
          'first': values.owner_first_name,
          'last': values.owner_last_name,
        },
      };

    fetch('/api/payments/create_checkout_session/', {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json'
      },
      'body': JSON.stringify({
        'plate': plate.id,
        'billing_email': values.email,
        'telephone': values.telephone,
        'billing_country': "GB",
        'name': my_name,
        'nominee_name': nominee['name'],
        'assign_registration': (values.assign_registration=="true"),
        'existing_registration': values.existing_registration,
        'shipping_country': "GB",
        'shipping_county': values.county,
        'shipping_city': values.town,
        'shipping_address_line1': values.address1,
        'shipping_address_line2': values.address2,
        'shipping_postal_code': values.postcode,
      }),
      'credentials': 'omit',
    })
    .then(response => response.json())
    .then(session => {
      popup(IFramePost,{
        href: session.href,
        data: Object.entries(session).reduce(
          (carry,[k,v]) => {
            if (k != 'href') { 
              carry[k.toUpperCase()] = v
            }
            return carry
          },
          {}
        ),
        callbacks: {
          'payment-complete': (event) => store.paymentComplete(
            event.data,
            plate,
            go_to_url
          )
        }
      })
    })
  }

export default do_checkout
