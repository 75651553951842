import React from 'react'
import {hasPlateDetail} from './hoc/has_plate_detail'
import {hasOrderDetail} from './hoc/has_order_detail'
import { observer } from 'mobx-react'
import {Helmet} from 'react-helmet'
import PlateCostBreakdown from './plate_cost_breakdown'
import Currency from './currency'

export const CheckoutComplete = ({order, plate}) => {
  return <>
  <Helmet>
    <title>{plate.text} Order Complete</title>
  </Helmet>
  <main className="max-w-screen-xl mx-auto lg:px-6 lg:py-12">
    <div id="#content" className="flex flex-col lg:grid lg:grid-cols-6 lg:gap-24">
      <article className="px-3 py-6 lg:p-0 lg:col-span-3">

        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center w-12 h-12 rounded-full border-2 border-green-600">
            <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6">
              <path d="M8.5 16.2578L24.3906 0.367188L26.5 2.47656L8.5 20.4766L0.132812 12.1094L2.24219 10L8.5 16.2578Z" fill="#0B9A40" className="text-green-600 fill-current" />
            </svg>
          </div>
          <div className="mt-8 text-center">
              <div className="font-medium text-xs uppercase tracking-wide text-gray-500">Order #{ order.order_id }</div>
              <h1 className="font-medium text-2xl lg:text-3xl">Thank you { order.name }!</h1>
              <p className="text-lg text-gray-700">Your purchase was successful, and your order confirmation has been sent to { order.email }.</p>
          </div>
        </div>

        <section id="#whathappensnext" className="my-12">

            
            <div className="relative flex flex-row items-start pl-3">
              <div className="absolute left-0 flex flex-col items-center justify-items-center bg-white w-6 pt-2 space-y-3">
                <div className="h-4 w-4 rounded-full bg-gray-300"></div>
              </div>
              <div className="border-l-2 pl-6 pb-8">
                <p className="text-gray-500">You paid <Currency amount={order.amount}/>.</p>
              </div>
            </div>

            
            <div className="relative flex flex-row items-start pl-3">
              <div className="absolute left-0 flex flex-col items-center justify-items-center bg-white w-6 mt-2 space-y-3">
                <div className="h-6 w-6 rounded-full border-4 border-blue-600 bg-white"></div>
              </div>
              <div className="border-l-2 pl-6 pb-8">
                <h3 className="text-lg lg:text-xl font-medium">Transfer</h3>
                <p className="text-gray-700">One of our transfer experts will begin transferring your registration to you immediately. We'll send updates to { order.email }, and contact you by { order.telephone } if we have any further queries.</p>
              </div>
            </div>

            
            <div className="relative flex flex-row items-start pl-3">
              <div className="absolute left-0 flex flex-col items-center justify-items-center bg-white w-6 space-y-3">
                <div className="h-4 w-4 rounded-full bg-gray-300"></div>
              </div>
              <div className="pl-6">
                <h3 className="text-lg lg:text-xl font-medium text-gray-500">Fit</h3>
                <p className="text-gray-500">Once your transfer is complete, we'll send your certificates to { order.email } and { order.address1 } { order.postcode }. We'll also send you detailed instructions on how to assign this registration to a vehicle.</p>
              </div>
            </div>

        </section>

      </article>
      <aside className="lg:col-span-3 lg:sticky">
        <div className="">
          <div className="px-3 py-3 border border-gray-400 bg-white rounded-lg shadow-sm lg:shadow-none lg:py-6 lg:px-6">
            <div className="inline-block">
              <div className="flex justify-center w-40 py-2 px-3 shadow-md rounded-md bg-plate">
                <span className="text-plate text-lg tracking-wider lg:text-2xl">{plate.text}</span>
              </div>
            </div>
            <div className="border-t divide-y mt-3 lg:mt-6">
              <PlateCostBreakdown plate={plate} paid={true}/>
            </div>
          </div>
          <div className="hidden py-3 flex items-center lg:block">
            <img src="/img/cards.png" className="w-2/3 mx-auto"/>
          </div>
        </div>
      </aside>
    </div>
  </main>
</>
}

export default observer(hasOrderDetail(CheckoutComplete))
