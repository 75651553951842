import React, {useContext} from 'react'
import {useParams} from 'react-router-dom'
import {RegistrationContext} from '../registration_context'
import {LoadingSpinner} from '../loading_spinner'

export const hasPlateDetail = (Component) => 
  ({plate, ...props}) => {
    const params = useParams()
    if (!plate) {
      plate = params.plate
    }
    const store = useContext(RegistrationContext)
    const plateDetail = store.getPlateDetail(plate)
    if (plateDetail) {
      return <Component plate={plateDetail} {...props}/>
    } else {
      return <LoadingSpinner/>
    }
  }

export default hasPlateDetail
