
class RestAPI {
  request(url,args) {
    const defaults = {
      method: 'get',
      params: null,
      body: null
    }
    args = Object.assign({}, defaults, args)

    var params = {
      method: args.method
    };
    if(args.body) {
      params.body = JSON.stringify(args.body)
      params.headers = {
        'Content-type': 'application/json',
      }
    }
    if (args.params) {
      url += "?" + Object.keys(args.params).map(
        k => encodeURIComponent(k) + "=" +encodeURIComponent(args.params[k])
      ).join('&')
    }
    return fetch('/api/'+url, params).then((response)=>response.json())
  }

}

class EasyRegAPI {
  constructor( rest ) {
    this.rest = rest
  }

  search( query ) {
    return this.rest.request('license_plates/', { params: query })
  }

  loadFavourites( ids ) {
    return this.rest.request('license_plates/by_ids/', { params: {ids: ids.join(",")}})
      .then((json) => json.results)
  }

  loadCountries() {
    return this.rest.request('countries')
  }

  checkRegistrationCompatibility(new_registration, existing_registration) {
    return this.rest.request(
      'license_plates/check_compatibility',
      { 
          params: {
              new_reg: new_registration,
              existing_reg: existing_registration
          }
      }
    ).then(
      (json) => json.compatibility,
    )
  }
}

export const api = new EasyRegAPI(new RestAPI())
export default api
