import React, { useContext, Fragment } from 'react'
import { RegistrationContext } from './registration_context'
import Pagination from 'rc-pagination'
import { observer } from 'mobx-react'
import { useHistory } from "react-router-dom";

export const RegistrationResultPagination = ({query, page}) => {
  const store = useContext(RegistrationContext)
  const history = useHistory()

  const results = store.getSearchResults(query, page)

  return <Pagination
    current={parseInt(page)}
    pageSize={store.pageSize}
    showSizeChanger={false}
    total={results.count}
    onChange={(selectedPage, size) => history.push('/search/'+query+'/'+selectedPage+'/')}
  />
}

export default observer(RegistrationResultPagination)
