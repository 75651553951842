import React, { Fragment, useState } from 'react'

export const Filter = ({name, prompt, options, selected, onChange}) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ stateSelected, setSelected ] = useState(selected)

  const apply = () => {
    setIsOpen(false)
    onChange(stateSelected)
  }

  return <Fragment>
          <div className="relative flex-shrink-0">
            <div className="border rounded-full px-4 py-2 text-sm cursor-pointer lg:text-base text-gray-700" onClick={()=>setIsOpen(!isOpen)}>Price</div>
            { isOpen && <Fragment>
              <div className="fixed z-40 left-0 top-0 right-0 bottom-0 bg-black bg-opacity-75"></div>
              <div className="fixed z-50 left-0 right-0 bottom-0 bg-white lg:border lg:absolute lg:mt-4 lg:w-64 lg:bottom-auto lg:rounded-lg lg:shadow-lg">
              <div className="flex items-center border-b px-3 py-3 font-medium">
                    {prompt}
                  <div className="flex items-center px-2 py-2 text-white bg-gray-200 rounded-full ml-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-gray-700 stroke-current w-2 h-2" style={{fill: 'none'}} onClick={()=>setIsOpen(false)}>
                      <line className="a" x1="0.75" y1="23.249" x2="23.25" y2="0.749"/>
                      <line className="a" x1="23.25" y1="23.249" x2="0.75" y2="0.749"/>
                    </svg>
                  </div>
                </div>
                <ol className="py-2">
                  {
                    Object.keys(options).map((k)=>{
                      const option = options[k]
                      return <li key={k} className="flex items-center py-2 px-3 space-x-2 hover:bg-gray-100">
                        <input type="radio" id={k} name={name} value={k} checked={stateSelected==k}
                        onChange ={() => setSelected(k)}
                        />
                        <label htmlFor={k} className="flex-grow text-gray-700">{option}</label>
                      </li>
                    })
                  }
                </ol>
                <div className="flex border-t px-3 py-3">
                  <button className="bg-blue-700 hover:bg-blue-800 text-white tracking-wide py-2 px-4 rounded ml-auto" onClick={apply}>Apply</button>
                </div>
              </div>
            </Fragment> }
          </div>
  </Fragment>
}

export default Filter
