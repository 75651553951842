import React from 'react'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import {hasPlateDetail} from './hoc/has_plate_detail'
import PlateCostBreakdown from './plate_cost_breakdown'
import { observer } from 'mobx-react'
import factory from '../factory'
import {Helmet} from 'react-helmet'
import {NiceMonth} from './nice_month'
import {FAQItem} from './faq_item'

export const PlateDetail = ({plate}) => {

  const [DetailLink, detail_params] = factory.get('DetailLink', {'plate':plate})

  const faqs = {
        "Do I have to assign it straight away?": "No, you don't need to transfer this registration immediately. You'll receive a certificate proving your ownership, which will expire in 10 years. You can assign it to a vehicle at any point during that time, or extend your ownership period for an additional fee.",

        "What is a DVLA transfer fee?": "The DVLA transfer fee is a mandatory £80 adminstration charge incurred whenever a registration is assigned to a vehicle.",

        "What documents do I need?": "In order to complete your transfer, we will need the name of the person as it appears in their vehicle's logbook. If this registration is intended as a gift, remember to check their vehicle's logbook first as they may not be the individual who registered and taxes the vehicle.",

        "Can I get a refund if I change my mind?": "If after completing your purchase we are no longer able to secure your registration, we will issue a full refund.  We cannot guarantee any refunds requested after the transfer process has begun.",

        "What happens to my current licence plate?": "If you'd like to retain your existing registration, you can do so by contacting the DVLA. Retaining your existing registration will incur an additional transfer fee of £80.",
        
        "How long does it take to transfer a registration?": "Transfers may take several weeks, but we aim to complete most registration transfers within 7 days.",

        "How can I check the progress of my order?": "We will notify you once your payment has been processed, and again when your transfer is complete. Your certificate should arrive by post within 5 – 7 working days of your completed registration.",

  }

  return <>
    <Helmet>
      <title>{plate.text} Private Plate</title>
    </Helmet>
    <main className="p-3 mx-auto max-w-screen-xl lg:p-6">

      <section id="#hero" className="relative flex items-center justify-center bg-gradient-to-r from-blue-700 to-blue-900 py-24 rounded-lg">
        <div className="absolute top-0 right-0 p-3 lg:p-6">
              <button className="h-10 w-10 flex justify-center items-center bg-blue-500 bg-opacity-25 text-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current w-4 h-4" style={{"fill": "none"}}>
                  <path d="M12,21.844l-9.588-10A5.672,5.672,0,0,1,1.349,5.293h0a5.673,5.673,0,0,1,9.085-1.474L12,5.384l1.566-1.565a5.673,5.673,0,0,1,9.085,1.474h0a5.673,5.673,0,0,1-1.062,6.548Z"/>
                </svg>
              </button>
        </div>

        <div className="inline-block py-2 px-8 bg-plate shadow-2xl rounded-md">
          <h1 className="text-plate text-5xl lg:text-6xl text-gray-900">{plate.text}</h1>
        </div>
      </section>

      <div id="#content" className="flex flex-col-reverse lg:grid lg:grid-cols-5 lg:gap-24">
        <article className="col-span-3 divide-y space-y-8 pb-12 lg:pt-6 lg:space-y-12">

          <section id="#whatsincluded" className="space-y-8 pt-6">
            <div className="space-y-2">
              <h2 className="text-xl font-medium lg:text-2xl">What You'll Get</h2>
              <p className="lg:text-lg text-gray-700">Buy this registration today and enjoy all the benefits of EasyReg's fully-managed professional transfer service.</p>
            </div>
            <ol className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <li className="flex items-center space-x-4">
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-current text-blue-600">
                  <path d="M6.31342 10.8533L2.16675 6.70659L0.753418 8.11992L6.31342 13.6666L18.2468 1.73325L16.8334 0.333252L6.31342 10.8533Z"/>
                </svg>
                <span className="text-blue-700 font-medium">Full registration ownership</span>
              </li>
              <li className="flex items-center space-x-4">
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-current text-blue-600">
                  <path d="M6.31342 10.8533L2.16675 6.70659L0.753418 8.11992L6.31342 13.6666L18.2468 1.73325L16.8334 0.333252L6.31342 10.8533Z"/>
                </svg>
                <span className="text-blue-700 font-medium">Digital and paper certificates</span>
              </li>
              <li className="flex items-center space-x-4">
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-current text-blue-600">
                  <path d="M6.31342 10.8533L2.16675 6.70659L0.753418 8.11992L6.31342 13.6666L18.2468 1.73325L16.8334 0.333252L6.31342 10.8533Z"/>
                </svg>
                <span className="text-blue-700 font-medium">Dedicated transfer agent</span>
              </li>
              <li className="flex items-center space-x-4">
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-current text-blue-600">
                  <path d="M6.31342 10.8533L2.16675 6.70659L0.753418 8.11992L6.31342 13.6666L18.2468 1.73325L16.8334 0.333252L6.31342 10.8533Z"/>
                </svg>
                <span className="text-blue-700 font-medium">Instant status updates</span>
              </li>
              <li className="flex items-center space-x-4">
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-current text-blue-600">
                  <path d="M6.31342 10.8533L2.16675 6.70659L0.753418 8.11992L6.31342 13.6666L18.2468 1.73325L16.8334 0.333252L6.31342 10.8533Z"/>
                </svg>
                <span className="text-blue-700 font-medium">Lightning transfer</span>
              </li>
              <li className="flex items-center space-x-4">
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-current text-blue-600">
                  <path d="M6.31342 10.8533L2.16675 6.70659L0.753418 8.11992L6.31342 13.6666L18.2468 1.73325L16.8334 0.333252L6.31342 10.8533Z"/>
                </svg>
                <span className="text-blue-700 font-medium">Help with vehicle assignment</span>
              </li>
            </ol>
          </section>

          <section id="#whathappensnext" className="space-y-8 pt-8 lg:pt-12">

            <div className="space-y-2">
              <h2 className="text-xl font-medium lg:text-2xl">What Happens Next</h2>
            </div>

            <div className="">

              <div className="relative flex flex-row items-start pl-3">
                <div className="absolute left-0 flex flex-col items-center justify-items-center bg-white w-6 pt-2 space-y-3">
                 <div className="h-6 w-6 rounded-full border-4 border-blue-600 bg-white"></div>
                </div>
                <div className="border-l-2 pl-6 pb-8">
                  <h3 className="text-lg font-medium">Buy</h3>
                  <p className="lg:text-lg text-gray-700">Pay securely online to complete your order.</p>
                </div>
              </div>

              <div className="relative flex flex-row items-start pl-3">
                <div className="absolute left-0 flex flex-col items-center justify-items-center bg-white w-6 mt-2 space-y-3">
                <div className="h-4 w-4 rounded-full bg-gray-300"></div>
                </div>
                <div className="border-l-2 pl-6 pb-8">
                  <h3 className="text-lg font-medium text-gray-500">Transfer</h3>
                  <p className="lg:text-lg text-gray-500">One of our transfer experts will begin transferring your registration to you immediately.</p>
                </div>
              </div>

              <div className="relative flex flex-row items-start pl-3">
                <div className="absolute left-0 flex flex-col items-center justify-items-center bg-white w-6 space-y-3">
                  <div className="h-4 w-4 rounded-full bg-gray-300"></div>
                </div>
                <div className="pl-6">
                  <h3 className="text-lg font-medium text-gray-500">Fit</h3>
                  <p className="lg:text-lg text-gray-500">Once your transfer is complete, we'll help you assign your registration or save it for later.</p>
                </div>
              </div>

              </div>
          </section>

          <section id="#faqs" className="space-y-8 pt-8">
            <div>
              <h2 className="text-2xl font-medium">Frequently Asked Questions</h2>
            </div>
            <div className="divide-y">
              
              {
                Object.entries(faqs).map(([key,val]) => 
                  <div key={key} className="py-3" id="faq__immediatefit">
                    <FAQItem title={key}>{val}</FAQItem>
                  </div>
                ) 
              }
            </div>
          </section>
        </article>

        <aside className="col-span-2">
          <div className="mt-3 lg:mt-0 lg:sticky lg:top-0 lg:pt-6">
            <div className="border rounded-lg shadow-sm lg:shadow-lg">
              <div className="p-6 divide-y">
                <div className="pb-6 space-y-3" id="price__summary">
                  <div className="pb-3">
                    <div className="text-xs uppercase text-gray-600">Our price:</div>
                    <div className="text-4xl leading-tight">&pound;{plate.price}</div>
                  </div>
                  <DetailLink {...detail_params}>
                    <button className="w-full bg-blue-700 py-3 px-4 rounded inline-flex justify-center items-center hover:bg-blue-800 transition duration-300">
                      <div className="text-white tracking-wide font-bold">Buy Now</div>
                    </button>
                  </DetailLink>
                  <div className="text-sm text-gray-600"><span className="font-medium">Please note:</span> This registration is limited to vehicles registered on or after <NiceMonth date={plate.minimum_valid_registration}/></div>
                </div>
                <PlateCostBreakdown plate={plate} showCards={false} showPlate={false} showBorder={false}/>
                
              </div>
            </div>
              <div className="py-3 flex items-center">
                <img src="/img/cards.png" className="w-2/3 mx-auto" />
              </div>
          </div>
        </aside>
      </div>
    </main>
  </>
}

export default observer(hasPlateDetail(PlateDetail))
