import React from 'react'
import {Form,Field,Radio,ScrollToTop} from './form'
import {required, valid_email, valid_telephone} from '../validations'

export const CheckoutContactDetails = ({onStageCompleted, values}) =>  {
  return <Form onSubmit={onStageCompleted} initialValues={values}>
    <div className="space-y-2 pb-6">
        <h1 className="font-medium text-2xl lg:text-3xl">Contact details</h1>
        <p className="text-gray-700 lg:text-lg">Tell us how to reach you with updates to your order.</p>
    </div>
    <div className="space-y-8">
      <div className="flex flex-col space-y-2">
        <label className="font-medium text-gray-800">Name</label>
        <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4">
          <Field name='salutation' validate={[required]}>{({field}) =>
            <select className="bg-white border border-gray-400 rounded p-3" {...field}>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Doctor">Doctor</option>
            </select>
          }</Field>
          <Field name='first_name' validate={[required]}>{({field}) =>
            <input className="flex-grow bg-white border border-gray-400 rounded p-3 flex-grow appearance-none lg:w-0" placeholder="First name" autoComplete='given-name' {...field}/>
          }</Field>
          <Field name='last_name' validate={[required]}>{({field}) =>
            <input className="flex-grow bg-white border border-gray-400 rounded p-3 flex-grow appearance-none lg:w-0" placeholder="Last name" autoComplete='family-name' {...field}/>
          }</Field>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <label className="font-medium text-gray-800">Email address</label>
        <Field name='email' validate={[required, valid_email]}>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none" placeholder="" type="email" autoComplete="email" {...field}/>
        }</Field>
      </div>
      <div className="flex flex-col space-y-2">
        <label className="font-medium text-gray-800">Telephone</label>
        <Field name='telephone' validate={[required, valid_telephone]}>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none" placeholder="" type="text" autoComplete="tel" {...field}/>
        }</Field>
      </div>
      <div className="flex flex-col space-y-2">
        <label className="font-medium text-gray-800">Delivery address</label>
        <Field name='address1' validate={[required]}>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none outline-none" placeholder="Address Line 1" {...field} autoComplete="address-line1"/>
        }</Field>
        <Field name='address2'>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none " placeholder="Address Line 2" {...field} autoComplete="address-line2"/>
        }</Field>
        <Field name='town' validate={[required]}>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none " placeholder="Town" {...field} autoComplete="address-level2"/>
        }</Field>
        <Field name='county' validate={[]}>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none " placeholder="County" {...field} autoComplete="address-level1"/>
        }</Field>
        <Field name='postcode' validate={[required]}>{({field}) =>
          <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none  w-1/2" placeholder="Postcode" {...field} autoComplete="postal-code"/>
        }</Field>
      </div>
    </div>
    <div className="flex flex-col space-y-3 mt-3 lg:flex-row-reverse lg:space-y-0"> 
      <button className="bg-blue-700 py-3 px-8 rounded inline-flex justify-center items-center transition duration-300 hover:bg-blue-800 lg:ml-auto" type='submit'>
        <span className="text-white tracking-wide font-bold">Continue</span>
      </button>
    </div>
  </Form>
}

export default CheckoutContactDetails

