import React, {Fragment, useEffect, useRef} from 'react'

export const IframePost = ({data,href,callbacks}) => {
  const formRef = useRef(null)
  useEffect(() => {
    formRef.current.submit()
    const callback = window.addEventListener('message', (message) => {
      try {
        message = JSON.parse(message.data)
      } catch(err) {
        return
      }

      if (!message.event) {
        return 
      }

      if (callbacks[message.event]) {
        callbacks[message.event](message)
      }
    })

    return () => window.removeEventListener('message', callback)
  })
  return <div className='iframe-wrapper'>
    <form target='iframe' action={href} method='post' ref={formRef} style={{"display":"none"}}>
      {Object.entries(data).map(
        ([k,v]) => (
          <input key={k} name={k} defaultValue={v}/>
        )
      )}
      <input type='submit'/>
    </form>
    <iframe name='iframe' className='w-full h-full'>
    </iframe>
  </div>
}

export default IframePost
