import React, {useContext} from 'react'
import {RegistrationContext} from './registration_context'
import {NiceMonth} from './nice_month'
import {Form,Field,Radio,ScrollToTop} from './form'
import {required, valid_email, valid_telephone} from '../validations'
import {LoadingSpinner} from './loading_spinner'
import {ASSIGNMENT_FEE} from '../settings'

export const CheckoutOwnerDetails = (
  {
    plate, onStageCompleted, onSelectStage, values,
    onSetIncludeAssignmentFee,
  }) => {

  const store = useContext(RegistrationContext)

  const [ assignRegistration, setAssignRegistration ] = React.useState(true)

  const global_validation = ({assign_registration, accept_assignment_responsibility, existing_registration }) => {
    var errors = {}
    if (!assign_registration) {
      if (!accept_assignment_responsibility) {
        errors['accept_assignment_responsibility'] = 'Please confirm your responsibility'
      }
    } else {
      if (existing_registration) {
        return store.checkRegistrationCompatibility(
          plate.text,
          existing_registration
        ).then((result) => 
          {
            if (!result) {
              errors['existing_registration'] = (
                "Your chosen registration cannot be transferred to this vehicle. "
                + "Assign it to a newer vehicle or select a different registration "
                + "to continue. For more info, call us."
              )
            }
            return errors
          },
          (err) => {
            errors['existing_registration'] = "Please provide a valid registration"
            return errors
          }
        )
      } else {
        errors['existing_registration'] = 'Required Field'
      }
    }

    if (errors!={}) {
      return errors
    }
  }

  const onChange = ({values}) => {
    if (values['assign_registration']!=assignRegistration) {
      setAssignRegistration(values['assign_registration'])
      onSetIncludeAssignmentFee(values['assign_registration'])
    }
  }

  return <Form
    onSubmit={onStageCompleted}
    initialValues={values}
    validate={global_validation}
    onChange={onChange}
  >
    <div className="space-y-2 pb-6">
        <h1 className="font-medium text-2xl lg:text-3xl">Owner details</h1>
        <p className="text-gray-700 lg:text-lg">Tell us about the person you're purchasing this for, so we can act on their behalf.</p>
    </div>
    <div className="space-y-8">
      <div className="flex flex-col space-y-2">
        <label className="font-medium text-gray-800">Owner's name</label>
        <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4">
          <Field name='owner_salutation' validate={[required]}>{({field}) =>
            <select className="bg-white border border-gray-400 rounded p-3" {...field}>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Doctor">Doctor</option>
            </select>
          }</Field>
          <Field name='owner_first_name' validate={[required]}>{({field}) =>
            <input className="flex-grow bg-white border border-gray-400 rounded p-3 flex-grow appearance-none lg:w-0" placeholder="First name" autoComplete='given-name' {...field}/>
          }</Field>
          <Field name='owner_last_name' validate={[required]}>{({field}) =>
            <input className="flex-grow bg-white border border-gray-400 rounded p-3 flex-grow appearance-none lg:w-0" placeholder="Last name" autoComplete='family-name' {...field}/>
          }</Field>
        </div>
        <p className="text-sm text-gray-700">Enter the person's name exactly as it appears in their logbook (also known as the V5C).</p>
      </div>
      <Field name='assign_registration'>{
        ({value}) => <>
          <div className="flex flex-col space-y-2">
            <div className="font-medium text-gray-800">Assign this registration to their vehicle?</div>
            <div className="flex flex-col border border-gray-400 rounded divide-y">
              <div className="flex flex-row items-center p-6 space-x-4 lg:p-6">
                <Radio name='assign_registration' id='self' value="" checked={!value}/>
                <label htmlFor="self" className="text-gray-700 w-full"><span className="font-medium">No</span>, I'll do this myself</label>
              </div>
              <div className="flex flex-row items-center p-6 space-x-4 lg:p-6">
                <Radio name='assign_registration' id='other' value="true" checked={value=="true"}/>
                <div className="flex flex-col lg:flex-row lg:w-full">
                  <label htmlFor="other" className="text-gray-700"><span className="font-medium">Yes</span>, assign this registration for me</label>
                  <div className="text-blue-700 font-medium lg:ml-auto">+&pound;{ASSIGNMENT_FEE}</div>
                </div>
              </div>
            </div>
          </div>

          {assignRegistration && <>
            <div className="flex flex-col space-y-2">
              <Field name='existing_registration'>{
                ({field}) => <>
                  <label className="font-medium text-gray-800">Vehicle's current registration</label>
                  <input className="bg-white border border-gray-400 rounded px-3 py-3 appearance-none" placeholder="" {...field}/>
                </>
              }</Field>
              <p className="text-sm text-gray-700">We'll automatically check if this vehicle can carry your new registration.</p>
            </div>
          </>}
        </>
      }</Field>
          {(!assignRegistration) && <>
            <div className="flex flex-row space-x-4">
              <Field name='accept_assignment_responsibility'>{
                ({field}) => <>
                  <input id="self__confirmation" className="bg-white border border-gray-400 rounded w-5 h-5 flex-shrink-0" type="checkbox" {...field}/>
                  <label htmlFor="self__confirmation" className="text-sm text-gray-700">I accept full responsibility for assigning this registration, and ensuring that it is assigned to a vehicle registered with the DVLA on or after <NiceMonth date={plate.minimum_valid_registration}/>.</label>
                </>
              }</Field>
            </div>
          </>}

      <div className="flex flex-col space-y-3 mt-3 lg:flex-row-reverse lg:space-y-0"> 
        <button className="bg-blue-700 py-3 px-8 rounded inline-flex justify-center items-center transition duration-300 hover:bg-blue-800 lg:ml-auto" type="submit">
          <span className="text-white tracking-wide font-bold">Continue</span>
        </button>
        <button className="bg-white py-3 px-8 rounded inline-flex justify-center items-center transition duration-300 hover:bg-gray-200" onClick={() => onSelectStage(0)}>
          <span className="text-gray-800 tracking-wide font-bold">&larr;&nbsp;Back</span>
        </button>
      </div>
    </div>
  </Form>
}

export default CheckoutOwnerDetails

