import React from 'react'
import {Helmet} from 'react-helmet'

export const PrivacyMain = () =>
  <>
    <Helmet>
      <title>EasyReg Privacy Policy</title>
    </Helmet>
    <main className="max-w-screen-xl px-3 py-6 mx-auto lg:px-6 lg:py-12 lg:grid lg:grid-cols-5 lg:gap-24">

      <article className="col-span-4 space-y-6">

        <section className="space-y-3">

          <h1 className="font-medium text-4xl">Privacy Policy</h1>
        </section>
      </article>
    </main>
    <main className="max-w-screen-xl px-3 py-6 mx-auto lg:px-6 lg:py-12 lg:grid lg:grid-cols-5 lg:gap-24">

      <article className="col-span-4 space-y-6">

        <section className="space-y-3">

          <h1 className="font-medium text-4xl">Privacy Policy</h1>

          <p className="text-gray-800">EasyReg Ltd understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, easy-reg.co.uk ("Our Site") and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</p>

          <p className="text-gray-800">Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of this Privacy Policy is requested when you visit the site.</p>

        </section>

        <ol className="list-decimal list-inside space-y-6 lg:list-outside">
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Definitions and Interpretation</h2>
            <p className="text-gray-800">In this Policy the following terms shall have the following meanings:</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12">
                  <td className="font-medium" width="50%">"Account"</td>
                  <td width="50%">means an account required to access and/or use certain areas and features of Our Site;</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">"Cookie"</td>
                  <td>means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in Part 14, below; and</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">"Cookie Law"</td>
                  <td>means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003;</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Information About Us</h2>
            <p className="text-gray-800">Our Site is owned and operated by EasyReg Ltd, a limited company registered in England under company number 12698440.</p>
            <p className="text-gray-800">Data Protection Officer: David Regan</p>
            <p className="text-gray-800">Email address: gdpr@easy-reg.co.uk</p>
            <p className="text-gray-800">Telephone number: 0808 175 6125</p>
            <p className="text-gray-800">Postal address: Flagship House, Fleet, Hampshire, GU51 4WD.</p>
          </li>
          <li className="space-y-2"><h2 className="font-medium text-2xl">What Does This Policy Cover?</h2>
            <p className="text-gray-800">This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">What Is Personal Data?</h2>
            <p className="text-gray-800">Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the "GDPR") and the Data Protection Act 2018 (collectively, "the Data Protection Legislation") as 'any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier'.</p>
            <p className="text-gray-800">Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">What Are My Rights?</h2>
            <p className="text-gray-800">Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:</p>
            <ol className="list-decimal space-y-3 pl-6">
              <li className="text-gray-800">The right to be informed about Our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 15.</li>
              <li className="text-gray-800">The right to access the personal data we hold about you. Part 13 will tell you how to do this.</li>
              <li className="text-gray-800">The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 15 to find out more.</li>
              <li className="text-gray-800">The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us using the details in Part 15 to find out more.</li>
              <li className="text-gray-800">The right to restrict (i.e. prevent) the processing of your personal data.</li>
              <li className="text-gray-800">The right to object to us using your personal data for a particular purpose or purposes.</li>
              <li className="text-gray-800">The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</li>
              <li className="text-gray-800">The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</li>
              <li className="text-gray-800">Rights relating to automated decision-making and profiling.</li>
            </ol>        
            <p className="text-gray-800">For more information about Our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 15.</p>
            <p className="text-gray-800">It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</p>
            <p className="text-gray-800">Further information about your rights can also be obtained from the Information Commissioner's Office or your local Citizens Advice Bureau.</p>
            <p className="text-gray-800">If you have any cause for complaint about Our use of your personal data, you have the right to lodge a complaint with the Information Commissioner's Office. we would welcome the opportunity to resolve your concerns ourselves, however, so please contact us first, using the details in Part 15.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">What Data Do You Collect and How?</h2>
            <p className="text-gray-800">Depending upon your use of Our Site, we may collect and hold some or all of the personal and non-personal data set out in the table below, using the methods also set out in the table. Please also see Part 14 for more information about Our use of Cookies and similar technologies. We do not collect any 'special category' or 'sensitive' personal data OR personal data relating to children OR data relating to criminal convictions and/or offences.</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12">
                  <td className="font-medium" width="50%">Data Collected</td>
                  <td className="font-medium" width="50%">How We Collect the Data</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Name</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Nominee Name</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Email Address</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Telephone Number</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Postal Address</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Purchased Registration Mark</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Current Registration Mark</td>
                  <td>By website or phone enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Search History</td>
                  <td>By website enquiries</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Purchase History</td>
                  <td>By website or phone purchases</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">IP address, browser type, operating system, pages visited</td>
                  <td>By website or phone enquiries</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How Do You Use My Personal Data?</h2>
            <p className="text-gray-800">Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The following table describes how we will use your personal data, and Our lawful bases for doing so:</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12">
                  <td className="font-medium" width="33%">What We Do</td>
                  <td className="font-medium" width="33%">What Data We Use</td>
                  <td className="font-medium" width="33%">Our Lawful Basis</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Supplying Our products AND/OR services to you.</td>
                  <td className="font-medium">Name , Nominee Name , Postal Address, Search History, Browser Type, Operating System</td>
                  <td className="">We use this information to provide you with the most appropriate registrations for your search.</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Managing payments for Our products AND/OR services.</td>
                  <td className="font-medium">Name , Nominee Name , Postal Address, Search History, Browser Type, Operating System</td>
                  <td className="">We use this information to complete your transfer on your behalf, and send you documents confirming your transfer.</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Supplying you with information by email AND/OR post that you have opted-in-to (you may opt-out at any time by contacting us).</td>
                  <td className="font-medium">Name , Email Address, Telephone Number, Postal Address</td>
                  <td className="">We use this information to contact you with offers relevant to your interests.</td>
                </tr>
              </tbody>
            </table>
            <p className="text-gray-800">With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by email AND telephone AND text message AND post with information, news, and offers on Our products AND services. You will not be sent any unlawful marketing or spam. we will always work to fully protect your rights and comply with Our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out. we will always obtain your express opt-in consent before sharing your personal data with third parties for marketing purposes and you will be able to opt-out at any time.</p>
            <p className="text-gray-800">Third Parties (including Google) whose content appears on Our Site may use third-party Cookies, as detailed below in Part 14. Please refer to Part 14 for more information on controlling cookies. Please note that we do not control the activities of such third parties, nor the data that they collect and use themselves, and we advise you to check the privacy policies of any such third parties.</p>
            <p className="text-gray-800">We use the following automated system[s] for carrying out certain kinds of decision-making AND profiling. If at any point you wish to query any action that we take on the basis of this or wish to request ‘human intervention’ (i.e. have someone review the action themselves, rather than relying only on the automated method), the Data Protection Legislation gives you the right to do so. Please contact us to find out more using the details in Part 15.</p>
            <ul className="space-y-3 list-disc pl-4">
              <li>The following automated decision-making method(s) may be used:
                  <li className="pl-4">You may be automatically served personalised served content, products, or services based on your device, operating system or profiling determined in the section below, for the purposes of improving your experience.</li>
              </li>
              <li>The following automated profiling may take place:
                  <li className="pl-4">We may automatically suggest registration marks to you, based on information you have provided for the purpose of improving your experience.</li>
              </li>
            </ul>
            <p className="text-gray-800">We will only use your personal data for the purpose(s) for which it was originally collected unless we reasonably believe that another purpose is compatible with that or those original purpose(s) and need to use your personal data for that purpose. If we do use your personal data in this way and you wish us to explain how the new purpose is compatible with the original, please contact us using the details in Part 15.</p>
            <p className="text-gray-800">If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the purpose(s) for which it was originally collected, we will inform you and explain the legal basis which allows us to do so.</p>
            <p className="text-gray-800">In some circumstances, where permitted or required by law, we may process your personal data without your knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your legal rights.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How Long Will You Keep My Personal Data?</h2>
            <p className="text-gray-800">We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12">
                  <td className="font-medium" width="50%">Type of Data</td>
                  <td className="font-medium" width="50%">How Long we Keep It</td>
                </tr>
                <tr className="h-12">
                  <td className="font-medium">Name, Nominee Name, Telephone Number, Email Address, Delivery Address, Registration Mark</td>
                  <td>We will retain this information until you inform us to remove it, such that we are able to efficiently act on your behalf if you choose to later offer your registration mark for private sale.</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How and Where Do You Store or Transfer My Personal Data?</h2>
            <p className="text-gray-800">We will only store or transfer some of your personal data within the European Economic Area (the “EEA”). The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein. This means that your personal data will be fully protected under the Data Protection Legislation, GDPR, and/or to equivalent standards by law.</p>
            <p className="text-gray-800">Please contact us using the details below in Part 15 for further information about the particular data protection mechanism[s] used by us when transferring your personal data to a third country.</p>
            <p className="text-gray-800">The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including the following:</p>
            <ul className="space-y-3 list-disc pl-4">
              <li>limiting access to your personal data to those employees, agents, contractors, and other third parties with a legitimate need to know and ensuring that they are subject to duties of confidentiality;</li>
              <li>procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, your personal data) including notifying you and/or the Information Commissioner’s Office where we are legally required to do so;</li>
            </ul>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Do You Share My Personal Data?</h2>
            <p className="text-gray-800">We will not share any of your personal data with any third parties for any purposes, subject to the following exception[s].</p>
            <p className="text-gray-800">If we sell, transfer, or merge parts of Our business or assets, your personal data may be transferred to a third party. Any new owner of Our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
            <p className="text-gray-800">In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
            <p className="text-gray-800">We may sometimes contract with the following third parties to supply certain products OR services.</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12 bg-grey-100">
                  <td className="font-medium" width="25%">Recipient</td>
                  <td className="font-medium" width="25%">Activity Carried Out</td>
                  <td className="font-medium" width="25%">Sector</td>
                  <td className="font-medium" width="25%">Information</td>
                </tr>
                <tr className="h-12">
                  <td>Sendgrid</td>
                  <td>Email Services Provider</td>
                  <td>Software</td>
                  <td>375 Beale St #300, San Francisco, CA 94105, USA</td>
                </tr>
                <tr className="h-12">
                  <td>Global Payments</td>
                  <td>Payment Processing</td>
                  <td>Financial Technology</td>
                  <td>The Observatory, 7-11 Sir John Rogerson's Quay, Dublin 2, Ireland</td>
                </tr>
                <tr className="h-12">
                  <td>Salesforce</td>
                  <td>Customer Relationship Management</td>
                  <td>Software</td>
                  <td>Salesforce Tower, 415 Mission Street, 3rd Floor, San Francisco, CA 94105</td>
                </tr>
              </tbody>
            </table>
            <p className="text-gray-800">If any of your personal data is shared with a third party, as described above, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, Our obligations, and the third party’s obligations under the law, as described above in Part 9.</p>
            <p className="text-gray-800">If any personal data is transferred outside of the EEA, we will take suitable steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation, as explained above in Part 9.</p>
            <p className="text-gray-800">If we sell, transfer, or merge parts of Our business or assets, your personal data may be transferred to a third party. Any new owner of Our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
            <p className="text-gray-800">In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How Can I Control My Personal Data?</h2>
            <p className="text-gray-800">In addition to your rights under the Data Protection Legislation, set out in Part 5, when you submit personal data via Our Site, you may be given options to restrict Our use of your personal data. In particular, we aim to give you strong controls on Our use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from us which you may do by unsubscribing using the links provided in Our emails.</p>
            <p className="text-gray-800">You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the Mailing Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing. Please note, however, that these services will not prevent you from receiving marketing communications that you have consented to receiving.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Can I Withhold Information?</h2>
            <p className="text-gray-800">You may access Our Site without providing any personal data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data.</p>
            <p className="text-gray-800">You may restrict Our use of Cookies. For more information, see Part 14.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How Can I Access My Personal Data?</h2>
            <p className="text-gray-800">If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.</p>
            <p className="text-gray-800">All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 15.</p>
            <p className="text-gray-800">There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover Our administrative costs in responding.</p>
            <p className="text-gray-800">We will respond to your subject access request within 30 days and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of Our progress.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How Do You Use Cookies?</h2>
            <p className="text-gray-800">Our Site may place and access certain first-party Cookies on your computer or device. First-party Cookies are those placed directly by us and are used only by us. we use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and services. we have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.</p>
            <p className="text-gray-800">By using Our Site, you may also receive certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites, services, and/or parties other than us. Third-party Cookies are used on Our Site for anonymously aggregating web traffic. For more details, please refer to the table below. These Cookies are not integral to the functioning of Our Site and your use and experience of Our Site will not be impaired by refusing consent to them.</p>
            <p className="text-gray-800">All Cookies used by and on Our Site are used in accordance with current Cookie Law.</p>
            <p className="text-gray-800">Before Cookies are placed on your computer or device, you will be shown a pop-up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of Our Site may not function fully or as intended.</p>
            <p className="text-gray-800">Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies to be “strictly necessary”. These Cookies are shown in the table below. Your consent will not be sought to place these Cookies, but it is still important that you are aware of them. You may still block these Cookies by changing your internet browser’s settings as detailed below, but please be aware that Our Site may not work properly if you do so. we have taken great care to ensure that your privacy is not at risk by allowing them.</p>
            <p className="text-gray-800">The following first-party Cookies may be placed on your computer or device:</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12 bg-grey-100">
                  <td className="font-medium" width="25%">Name of Cookie</td>
                  <td className="font-medium" width="50%">Purpose</td>
                  <td className="font-medium" width="25%">Strictly Necessary</td>
                </tr>
                <tr className="h-12">
                  <td>Filename</td>
                  <td>Description</td>
                  <td>Yes / No</td>
                </tr>
              </tbody>
            </table>
            <p className="text-gray-800">and the following third-party Cookies may be placed on your computer or device:</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12 bg-grey-100">
                  <td className="font-medium" width="25%">Name of Cookie</td>
                  <td className="font-medium" width="25%">Provider</td>
                  <td className="font-medium" width="50%">Purpose</td>
                </tr>
                <tr className="h-12">
                  <td>Filename</td>
                  <td>Provider</td>
                  <td>Description</td>
                </tr>
              </tbody>
            </table>
            <p className="text-gray-800">Our Site uses analytics services provided by Google. Website analytics refers to a set of tools used to collect and analyse anonymous usage information, enabling us to better understand how Our Site is used. This, in turn, enables us to improve Our Site and the products and services offered through it.</p>
            <p className="text-gray-800">The analytics service(s) used by Our Site use(s) Cookies to gather the required information. You do not have to allow us to use these Cookies, however whilst Our use of them does not pose any risk to your privacy or your safe use of Our Site, it does enable us to continually improve Our Site, making it a better and more useful experience for you.</p>
            <p className="text-gray-800">The analytics service(s) used by Our Site use(s) the following Cookies:</p>
            <table className="text-gray-800 w-full">
              <tbody className="divide-y">
                <tr className="h-12 bg-grey-100">
                  <td className="font-medium" width="25%">Name of Cookie</td>
                  <td className="font-medium" width="25%">First / Third Party</td>
                  <td className="font-medium" width="25%">Provider</td>
                  <td className="font-medium" width="25%">Purpose</td>
                </tr>
                <tr className="h-12">
                  <td>Filename</td>
                  <td>First / Third</td>
                  <td>Provider</td>
                  <td>Purpose</td>
                </tr>
              </tbody>
            </table>
            <p className="text-gray-800">In addition to the controls that we provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies or only third-party Cookies. By default, most internet browsers accept Cookies, but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</p>
            <p className="text-gray-800">You can choose to delete Cookies on your computer or device at any time, however you may lose any information that enables you to access Our Site more quickly and efficiently including, but not limited to, login and personalisation settings.</p>
            <p className="text-gray-800">It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">How Do I Contact You?</h2>
            <p className="text-gray-800">To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details [(for the attention of David Regan)]:</p>
            <p className="text-gray-800">Email address: gdpr@easy-reg.co.uk</p>
            <p className="text-gray-800">Telephone number: 0808 196 4560</p>
            <p className="text-gray-800">Postal Address: Flagship House, Fleet, Hampshire, GU51 4WD</p>
          </li>
          <li className="space-y-2">
            <h2 className="font-medium text-2xl">Changes to this Privacy Policy</h2>
            <p className="text-gray-800">We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change Our business in a way that affects personal data protection.</p>
            <p className="text-gray-800">Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. we recommend that you check this page regularly to keep up-to-date. This Privacy Policy was last updated on 25 November 2020.</p>
          </li>
        </ol>
      </article>

    </main>
  </>

export default PrivacyMain
