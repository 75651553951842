import React, { useContext, Fragment } from 'react'
import { RegistrationContext } from './registration_context'
import { observer } from 'mobx-react'

export const Popup = () => {
  const store = useContext(RegistrationContext)

  if ( ! store.popup) {
    return <Fragment/>
  } else {
    const [ Component, params ] = store.popup

    return <div className='fixed inset-0 z-50 bg-black bg-opacity-75'>
      <div className='relative rounded-lg h-full w-full max-w-lg mx-auto md:my-20'>
        <button onClick={() => store.hidePopup()} className='close absolute top-0 right-0 flex justify-center items-center p-2 m-4 text-white bg-gray-200 rounded-full ml-auto'>
          <svg xmlns="[[w3.org/2000/svg](http://www.w3.org/2000/svg)](http://w3.org/2000/svg](http://www.w3.org/2000/svg))" viewBox="0 0 24 24" class="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
            <circle class="a" cx="10" cy="10" r="10" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
            <line class="a" x1="3" y1="3" x2="17" y2="17"></line>
            <line class="a" x1="3" y2="3" x2="17" y1="17"></line>
          </svg>
        </button>
    
        <Component {...params}/>   
      </div>
    </div>
  }
}

export default observer(Popup)
