import { autorun, observable, makeObservable, runInAction } from 'mobx'
import api from '../api'

export class RegistrationStore {
  searchResults = null
  plateDetails = {}
  searchError = null
  numResults = null
  results = {}
  searchesRun = {}
  searching = false
  page = 1
  pageSize = 10
  favourites = []
  favourite_ids = []
  orders = {}
  popup = null 
  countries = null
  filters = {
    "q": "",
  }

  constructor() {
    makeObservable(
      this,
      {
        searchResults: observable,
        plateDetails: observable,
        searchError: observable,
        numResults: observable,
        results: observable,
        searchesRun: observable,
        searching: observable,
        page: observable,
        favourite_ids: observable,
        favourites: observable,
        filters: observable,
        popup: observable,
        countries: observable,
      }
    )

    var storedFavourites = localStorage.getItem('favourites')
    this.favourite_ids = storedFavourites ? storedFavourites.split(",") : []
    try {
      var orders = JSON.parse(
        localStorage.getItem('orders')
      )
      if (orders) {
	      this.orders = orders
      }
    } catch (err) {
      this.orders = {}
    }

    autorun(() => this.loadFavourites())

  }

  search( text ) {
    this.setFilterSelection('q', text)
    this.page = 1
  }

  goToPage( page ) {
    this.page = page
  }
  
  isFavourited( plate ) {
    return this.favourite_ids.includes(plate)
  }

  toggleFavouriteItem( plate ) {
    if (this.favourite_ids.includes(plate)) {
      this.favourite_ids = this.favourite_ids.filter((test) => test != plate)
    } else {
      this.favourite_ids.push(plate)
    }
    localStorage.setItem('favourites', this.favourite_ids)
  }

  getFilterSelection( k ) {
    return this.filters[k]
  }

  setFilterSelection( k, v ) {
    this.filters[k] = v
  }

  loadFavourites() {
    if (this.favourite_ids) {
      api.loadFavourites(this.favourite_ids)
        .then((favourites) => {
            this.favourites = favourites
          })
    } else {
      this.favourites = []
    }
  }

  getPlateDetail(plate_string) {
    const store = this
    if (!this.plateDetails[plate_string]) {
      api.loadFavourites([plate_string])
        .then((plates) => {
            store.plateDetails[plate_string] = plates[0]
        })
    }
    return this.plateDetails[plate_string]
  }

  getSearchResults(query, page) {
    const store = this
    const filters = Object.assign({}, this.filters, {'page': page, 'q': query})

    if (!this.haveSearched(filters)) {
      this.loadResults(filters)
    }
    return this.getResults(filters)
  }

  haveSearched(filters) {
    const key = JSON.stringify(filters)
    return this.searchesRun[key]
  }

  loadResults(filters) {
    const key = JSON.stringify(filters)
    this.searchesRun[key] = true
    this.results[key] = { 'state': 'running' }
    api.search(filters).then(
      (json) => {
        runInAction(() => {
          this.results[key] = {
            'state': 'complete',
            'results': json['results'],
            'count': json['count'],
          }
        })
      }
    ).catch(e => {
      runInAction(() => {
        this.results[key] = {
          'state': 'error',
          'error': e,
        }
      })
    });
  }

  setSearchResults(key, results, count) {
    this.results[key] = {'results': results, 'total': count}
  }

  setSearchError(key, error) {
    this.searchesRun[key] = false
    this.results[key] = { 'error': error}
  }

  getResults(filters) {
    const key = JSON.stringify(filters)
    if (this.results[key]) {
      return this.results[key]
    }
  }

  getCountries() {
    const store = this
    if (!this.countries) {
      api.loadCountries().then(
        (countries) => {
          store.countries = countries
        }
      )
    }
    return this.countries
  }

  getOrderDetail(orderId) {
    return this.orders[orderId]
  }

  showPopup(component,params) {
    const store = this
    runInAction(() => {
      store.popup = [ component, params ]
    })
  }

  hidePopup() {
    this.popup = null 
  }

  paymentComplete(order, plate, go_to_url) {
    const store = this

    runInAction(() => {
      store.orders[order.order_id] = {
        'order': order,
        'plate': plate,
      }
      localStorage.setItem('orders', JSON.stringify(store.orders))
    });

    switch(order.status){
      case 'OrderStatus.COMPLETE':
        go_to_url('/checkout/complete/'+order.order_id)
        break
      default:
        go_to_url('/checkout/failed/'+order.order_id)
    }
    this.hidePopup()
  }

  checkRegistrationCompatibility = (new_registration, existing_registration) => {
    return api.checkRegistrationCompatibility(new_registration, existing_registration)
  }
}

export default RegistrationStore
