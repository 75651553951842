import React, { useContext, Fragment } from 'react'
import { observer } from 'mobx-react'

import { RegistrationContext } from './registration_context'
import LoadingSpinner from './loading_spinner'
import RegistrationList from './registration_list'

export const RegistrationSearchResults = ({query, page}) => {
  const store = useContext(RegistrationContext)

  const results = store.getSearchResults(query, page)
  const hasResults = results && ( results.length > 0 )
  const didSearch = ! (store.searching || (results == null))

  return <Fragment>
    { (results.state == 'running') && <LoadingSpinner/> }
    { ( results.error ) && 
      <p>{results.error.toString()}</p>
    }
    { (results.state =='complete') && (results.count==0) &&
			<div id="results--empty" className="p-3 lg:p-6 space-y-2 border-t">
				<h2 className="text-lg md:text-xl">No results found</h2>
				<p className="text-gray-600 md:text-lg">Try removing any filters or updating your search. For the best results, try using meaningful initials, 2-digit numbers or letter-number combinations like A1.</p>
			</div>
    }

    { (results.state =='complete') && (results.count>0) &&
      <RegistrationList registrations={results['results']}/>
    }
    </Fragment>
}

export default observer(RegistrationSearchResults)
