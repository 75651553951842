import React from 'react'
import {Link} from 'react-router-dom'
import HeaderSearchForm from './header_search_form'
import Unimplemented from './unimplemented'

export const Header = () =>
  <header className="border-b z-30">

    <div className="bg-blue-900">
      <div className="max-w-screen-xl flex lg:justify-start lg:mx-auto">
        <div className="px-3 py-3 space-x-2 lg:block lg:px-6">
          <span className="text-sm text-white">Have a question?</span>
          <span className="hidden text-sm text-blue-200 lg:inline-block">Experts available 9am &ndash; 5pm, Mon &ndash; Fri</span>
        </div>
        <div className="flex py-3 ml-auto lg:divide-x lg:divide-blue-700">
          <Unimplemented>
          <a className="hidden items-center px-6 space-x-2 lg:flex" href="">
            <svg width="16" height="16" viewBox="0 0 16 16" class="text-blue-200 fill-current" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5 5.01172V3.5H3.5V5.01172H12.5ZM9.51172 9.51172V8H3.5V9.51172H9.51172ZM3.5 5.75V7.26172H12.5V5.75H3.5ZM14.0117 0.511719C14.4102 0.511719 14.75 0.664062 15.0312 0.96875C15.3359 1.25 15.4883 1.58984 15.4883 1.98828V10.9883C15.4883 11.3867 15.3359 11.7383 15.0312 12.043C14.75 12.3477 14.4102 12.5 14.0117 12.5H3.5L0.511719 15.4883V1.98828C0.511719 1.58984 0.652344 1.25 0.933594 0.96875C1.23828 0.664062 1.58984 0.511719 1.98828 0.511719H14.0117Z"/>
            </svg>
            <span className="text-sm text-white">Live Chat</span>
          </a>
          </Unimplemented>
          <a className="flex items-center px-3 space-x-2 lg:flex lg:px-6" href="tel:+448081756125">
            <svg width="14" height="14" viewBox="0 0 14 14" className="text-blue-200 fill-current" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.95703 6.08594C4.08203 8.26562 5.73438 9.91797 7.91406 11.043L9.56641 9.39062C9.80078 9.15625 10.0586 9.09766 10.3398 9.21484C11.1836 9.49609 12.0742 9.63672 13.0117 9.63672C13.2227 9.63672 13.3984 9.70703 13.5391 9.84766C13.6797 9.98828 13.75 10.1641 13.75 10.375V13.0117C13.75 13.2227 13.6797 13.3984 13.5391 13.5391C13.3984 13.6797 13.2227 13.75 13.0117 13.75C9.49609 13.75 6.48438 12.5078 3.97656 10.0234C1.49219 7.51562 0.25 4.50391 0.25 0.988281C0.25 0.777344 0.320312 0.601562 0.460938 0.460938C0.601562 0.320312 0.777344 0.25 0.988281 0.25H3.625C3.83594 0.25 4.01172 0.320312 4.15234 0.460938C4.29297 0.601562 4.36328 0.777344 4.36328 0.988281C4.36328 1.92578 4.50391 2.81641 4.78516 3.66016C4.87891 3.96484 4.82031 4.22266 4.60938 4.43359L2.95703 6.08594Z"/>
            </svg>
            <span className="text-sm text-white">Call free on <span className="font-bold">0808 175 6125</span></span>
          </a>
        </div>
      </div>
    </div>

    {/* Search Section */}
    <div className="bg-white">
      <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-3 lg:flex-no-wrap lg:h-20">
        <Link to="/" className="text-lg font-medium text-blue-700 p-3">EasyReg</Link>

        <HeaderSearchForm/>
        
        <div className="flex divide-x items-center ml-auto">
          <Link to='/favourites' >  
            <button className="bg-white inline-flex items-center rounded space-x-2 p-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current text-blue-700 w-4 h-4" viewBox="0 0 24 24" style={{fill: 'none'}}>
                <path d="M12,21.844l-9.588-10A5.672,5.672,0,0,1,1.349,5.293h0a5.673,5.673,0,0,1,9.085-1.474L12,5.384l1.566-1.565a5.673,5.673,0,0,1,9.085,1.474h0a5.673,5.673,0,0,1-1.062,6.548Z"/>
              </svg>
              <span className="text-gray-700 lg:inline-block hover:text-gray-700">Favourites</span>
            </button>
          </Link>
          <Unimplemented>
          <button className="bg-white inline-flex items-center rounded py-3 px-4 space-x-2 lg:py-2 lg:px-6">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current text-blue-700 w-4 h-4" viewBox="0 0 24 24" style={{fill: 'none'}}>
              <path d="M6,19.125h9.921A1.5,1.5,0,0,0,17.4,17.876l2.637-15.5a1.5,1.5,0,0,1,1.479-1.248H22.5"/>
              <path className="a" d="M7.875,21.375a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"/>
              <path className="a" d="M15.375,21.375A.375.375,0,1,1,15,21.75a.375.375,0,0,1,.375-.375"/>
              <path className="a" d="M17.953,14.625H5.882a3,3,0,0,1-2.91-2.272l-1.45-5.8a.75.75,0,0,1,.728-.932H19.484"/>
            </svg>
            <span className="hidden text-gray-700 lg:inline-block hover:text-gray-700">Basket</span>
          </button>
          </Unimplemented>
        </div>
      </div>
    </div>

    {/* Checkout Section
    <div className="bg-white border-b">
      <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-3 lg:flex-no-wrap lg:h-20">
        <div className="flex items-center">
          <a href="home.html" className="text-lg font-medium text-blue-700 p-3">EasyReg</a>
        </div>
        <div className="flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="absolute right-0 stroke-current text-gray-600 w-4 h-4 mr-3 lg:relative lg:mr-2 lg:block" style={{fill: 'none'}}>
            <rect className="a" x="3.75" y="9.75" width="16.5" height="13.5" rx="1.5" ry="1.5"></rect>
            <path className="a" d="M6.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75"></path>
            <circle className="a" cx="12" cy="16.5" r="2.25" fill="#fff"></circle>
          </svg>
          <h2 className="text-lg text-gray-700"><span className="hidden lg:inline-block">Secure&nbsp;</span>Checkout</h2>
        </div>
        <div className="">
        </div>
      </div>
    </div>

    <div className="bg-white border-b flex px-3 lg:px-6 lg:justify-center">
      <div className="flex flex-row items-center space-x-2 py-3">
        <div className="font-medium text-blue-700">Transfer</div>
        <div className="">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current w-2 h-2" style={{fill: 'none'}}>
            <path className="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"></path>
          </svg>
        </div>
        <div className="text-gray-500">Contact</div>
        <div className="">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current w-2 h-2" style={{fill: 'none'}}>
            <path className="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"></path>
          </svg>
        </div>
        <div className="text-gray-500">Confirm &amp; Pay</div>
      </div>
    </div>
    Checkout Section Ends */}


    {/* Benefits Section */}
    <div className="hidden lg:block bg-gray-100">
      <div className="max-w-screen-xl mx-auto grid grid-cols-4">
        <div className="flex px-6 py-4 space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-blue-700 stroke-current w-6 h-6 mr-2 mt-1" style={{fill: 'none'}}>
            <path d="M4.5,18H3.75a3,3,0,0,1-3-3V12a3,3,0,0,1,3-3H4.5a.75.75,0,0,1,.75.75v7.5A.75.75,0,0,1,4.5,18Z"></path>
            <path d="M20.25,18H19.5a.75.75,0,0,1-.75-.75V9.75A.75.75,0,0,1,19.5,9h.75a3,3,0,0,1,3,3v3A3,3,0,0,1,20.25,18Z"></path>
            <path d="M3.75,9A8.25,8.25,0,0,1,12,.75h0A8.25,8.25,0,0,1,20.25,9"></path>
            <path d="M15,21.75h2.25a3,3,0,0,0,3-3h0V18"></path>
            <path d="M13.5,23.25H12a1.5,1.5,0,0,1-1.5-1.5h0a1.5,1.5,0,0,1,1.5-1.5h1.5a1.5,1.5,0,0,1,1.5,1.5h0A1.5,1.5,0,0,1,13.5,23.25Z"></path><path className="a" d="M9,15.442a4.454,4.454,0,0,0,6,0"></path>
            <line x1="9" y1="9.985" x2="9" y2="11.297"></line>
            <line x1="15" y1="9.985" x2="15" y2="11.297"></line>
          </svg>
          <div className="flex flex-col">
            <h4 className="text-sm text-gray-700 leading-tight">Expert Support</h4>
            <div className="text-sm text-gray-600">Talk to someone today</div>
          </div>
        </div>
        <div className="flex px-6 py-4 space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-blue-700 stroke-current w-6 h-6 mr-2 mt-1" style={{fill: 'none'}}>
          <path d="M18.825,12.374l1.251,2.252h2.481a.68.68,0,0,1,.489,1.163l-2.278,2.486,1.262,2.9a.688.688,0,0,1-.979.86L18,20.318l-3.05,1.716a.688.688,0,0,1-.98-.86l1.22-2.8"/>
          <path d="M5.175,12.374,3.924,14.626H1.443a.68.68,0,0,0-.489,1.163l2.278,2.486-1.262,2.9a.688.688,0,0,0,.979.86L6,20.318l3.05,1.716a.688.688,0,0,0,.98-.86l-1.22-2.8"/>
          <path d="M12.368,2.141l1.989,3.977,3.827.383a.485.485,0,0,1,.294.825l-3.152,3.152,1.168,4.283a.484.484,0,0,1-.684.56l-3.875-1.937L8.06,15.321a.484.484,0,0,1-.683-.56l1.168-4.283L5.393,7.326A.485.485,0,0,1,5.687,6.5l3.826-.383L11.5,2.141A.484.484,0,0,1,12.368,2.141Z"/>
        </svg>
          <div className="flex flex-col">
            <h4 className="text-sm text-gray-700 leading-tight">5 star service</h4>
            <div className="text-sm text-gray-600">Shop with confidence</div>
          </div>
        </div>
        <div className="flex px-6 py-4 space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-blue-700 stroke-current w-6 h-6 mr-2 mt-1" style={{fill: 'none'}}>
            <rect x="5.25" y="18.75" width="13.5" height="4.5" rx="1.5" ry="1.5"></rect>
            <path d="M8.25.75H1.773A1.023,1.023,0,0,0,.75,1.773a11.25,11.25,0,0,0,22.5,0A1.023,1.023,0,0,0,22.227.75H15.75"></path>
            <polyline className="a" points="9.882 12.824 8.139 18.75 15.639 18.75 14.063 12.834"></polyline>
            <path d="M12.6,1.075l1.248,2.458h2.127a.583.583,0,0,1,.419,1L14.438,6.452,15.52,8.937a.589.589,0,0,1-.84.736L12.065,8.2,9.45,9.673a.589.589,0,0,1-.839-.736L9.693,6.452,7.74,4.53a.583.583,0,0,1,.42-1h2.126l1.249-2.458A.6.6,0,0,1,12.6,1.075Z"></path>
          </svg>
          <div className="flex flex-col">
            <h4 className="text-sm text-gray-700 leading-tight">Low-Price Guarantee</h4>
            <div className="text-sm text-gray-600">We beat any price</div>
          </div>
        </div>
        <div className="flex px-6 py-4 space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-blue-700 stroke-current w-6 h-6 mr-2 mt-1" style={{fill: 'none'}}>
            <circle cx="12" cy="14.25" r="9"></circle>
            <line x1="18" y1="7.5" x2="19.875" y2="5.625"></line>
            <line x1="19.5" y1="5.25" x2="20.25" y2="6"></line>
            <line x1="12" y1="5.25" x2="12" y2="0.75"></line>
            <line x1="14.25" y1="0.75" x2="9.75" y2="0.75"></line>
            <line x1="12" y1="15" x2="8.25" y2="10.849"></line>
          </svg>
          <div className="flex flex-col">
            <h4 className="text-sm text-gray-700 leading-tight">Lightning Transfers</h4>
            <div className="text-sm text-gray-600">Fit by next week</div>
          </div>
        </div>
      </div>
    </div>
  </header>


export default Header
