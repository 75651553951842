import React from 'react'

export const FAQItem = ({title,children}) => {
  const [open, setOpen] = React.useState(false)

  return <>
      <dl className="flex items-center" onClick={() => setOpen(!open)}>
        <div className="lg:text-lg">{title}</div>	
        { (!open) && 
          <div className="ml-auto">
            <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
              <path d="M8.44531 0.433594L9.5 1.48828L5 5.98828L0.5 1.48828L1.55469 0.433594L5 3.87891L8.44531 0.433594Z" fill="#A0AEC0"/>
            </svg>
          </div> 
        }
        { (open) && 
          <div className="ml-auto">
            <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
              <path d="M8.44531 0.433594L9.5 1.48828L5 5.98828L0.5 1.48828L1.55469 0.433594L5 3.87891L8.44531 0.433594Z" fill="#A0AEC0"/>
            </svg>
          </div> 

        }
      </dl>
      { open && 
        <dt className="text-gray-700">{children}</dt>
      }
    </>
}

export default FAQItem
