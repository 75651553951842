import React, {useContext} from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'

import factory from '../factory'
import {RegistrationContext} from './registration_context'
import {hasPlateDetail} from './hoc/has_plate_detail'

import PlateCostBreakdown from './plate_cost_breakdown'

import CheckoutHeader from './checkout_header'
import CheckoutHowItWorks from './checkout_how_it_works'
import CheckoutContactDetails from './checkout_contact_details'
import CheckoutOwnerDetails from './checkout_owner_details'
import CheckoutConfirm from './checkout_confirm'

const checkoutStages = [
//  CheckoutHowItWorks,
  CheckoutContactDetails,
  CheckoutOwnerDetails,
  CheckoutConfirm,
]

export const Checkout = ({plate}) => {
  const [checkoutStage, setCheckoutStage] = React.useState(0)
  const [checkoutValues, setCheckoutValues] = React.useState({
    "accept_assignment_responsibility": "",
    "salutation": "Mr",
    "first_name": "",
    "last_name": "",
    "owner_salutation": "Mr",
    "owner_first_name": "",
    "owner_last_name": "",
    "existing_registration": "",
    "shipping_county": "",
  })
  const [includeAssignmentFee, setIncludeAssignmentFee] = React.useState(false)

  const store = useContext(RegistrationContext)
  const history = useHistory()

  const do_checkout = (values) => {
    const fn = factory.get('CheckoutFunction')
    fn({
      plate: plate,
      values:values,
      store: store,
      popup: store.showPopup.bind(store),
      go_to_url: (url) => history.push(url)
    })
  }

  const stageCompleted = (values) => {
    const nextStage = checkoutStage + 1
    const combinedValues = {}
    Object.assign(combinedValues, checkoutValues)
    Object.assign(combinedValues, values)

    setCheckoutValues(combinedValues)

    if(nextStage>=checkoutStages.length) {
      do_checkout(combinedValues)
    } else {
      setCheckoutStage(nextStage)
    } 
  }

  const Stage = checkoutStages[checkoutStage]

  return <>
    <CheckoutHeader stage={checkoutStage}/>
    <main className="max-w-screen-lg mx-auto px-3 lg:px-6 lg:pb-12">
      <div id="#content" className="flex flex-col lg:grid lg:grid-cols-12 lg:gap-12">
        <article className="space-y-8 lg:col-span-7">
          <Stage
            plate={plate}
            values={checkoutValues}
            onStageCompleted={stageCompleted}
            onSelectStage={(stage) => setCheckoutStage(stage)}
            onSetIncludeAssignmentFee = {(value) => setIncludeAssignmentFee(value)}
          />
        </article>
        <aside className="my-8 lg:my-0 lg:col-span-5">
          <PlateCostBreakdown plate={plate} includeAssignmentFee={includeAssignmentFee}/>
        </aside>
      </div>
    </main>
  </>
}

export default observer(hasPlateDetail(Checkout))
