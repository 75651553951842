import setup from './mappings'

export class Factory {

  get(key, params) {
    if(this.generators[key]) {
      return this.generators[key](params)
    }

    if(!this.singleton_instances[key]) {
      if(!this.singleton_generators[key]) {
        this.singleton_instances[key] = new key
      } else {
        this.singleton_instances[key] = this.singleton_generators[key]()
      }
    }
    return this.singleton_instances[key]
  }

  singleton_instances = {}
  singleton_generators = {}
  generators = {}

  singleton(key, callback) {
    this.singleton_generators[key] = callback
  }

  register(key, callback) {
    this.generators[key] = callback
  }
}

const factory = new Factory()

setup(factory)

export default factory;
