import React from 'react'
import RegistrationSearchResults from './registration_search_results'
import RegistrationResultPagination from './registration_result_pagination'
import SearchFilters from './search_filters'
import {Helmet} from 'react-helmet'

export const SearchMain = ({match}) => 
  <>
    <Helmet>
      <title>{match.params.query.toUpperCase()} Private Plates</title>
    </Helmet>
    <main className="max-w-screen-xl mx-auto">
      <div id="#content" className="flex flex-col lg:grid lg:grid-cols-5 lg:gap-24">
      <section className="w-full lg:pb-12 lg:col-span-3">
        <div className="px-3 py-6 space-y-2 lg:px-6 lg:py-10">
          <div>
            <div className="text-sm text-gray-600">1,000+ results for</div>
            <h1 className="text-2xl font-medium lg:text-4xl"><span className="font-bold">{match.params.query.toUpperCase()}</span> Private Plates</h1>
          </div>
          <SearchFilters/>
        </div>
        <RegistrationSearchResults query={match.params.query} page={match.params.page || 1}/>
        <RegistrationResultPagination query={match.params.query} page={match.params.page || 1}/>
      </section>
      <aside className="col-span-2">
        <div className="lg:sticky top-0 py-12">
          <div className="bg-white space-y-6">
            <img src="/img/people.png" className="pb-3"/>
            <div className="px-4 md:px-6">
              <h2 className="text-lg font-medium md:text-xl">Need a hand?</h2>
              <p className="text-gray-600 md:text-lg">Questions about the process? Stuck for ideas? Get in touch for instant help from an expert advisor.</p>
              <ol className="py-6 space-y-4">
                <li><a href="tel:+448081756125" className="font-medium text-blue-700 lg:text-lg">0808 175 6125</a></li>
                <li><a href="mailto:help@easy-reg.co.uk" className="font-medium text-blue-700 lg:text-lg">help@easy-reg.co.uk</a></li>
              </ol>
            </div>
          </div>
        </div>
      </aside>
      </div>
    </main>
  </>

export default SearchMain
