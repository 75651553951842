import React from 'react'

export const NiceMonth = ({date}) => {
  const parts = date.split("-")

  const year = parts[0]
  const month = {
    "03": "March",
    "09": "September"
  }[parts[1]]

  return <>
    {month} {year}
  </>
}

export default NiceMonth

