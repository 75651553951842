import React from 'react'
import RegistrationSearchForm from './registration_search_form'

export const HomePageSearchForm = () =>
  <RegistrationSearchForm autoSubmit={false} showOnHome={true}>
    {(props, callbacks) => 
      <div className="flex relative">
        <input {...props} className="flex-grow bg-white appearance-none rounded-full py-3 px-6 shadow-lg focus:outline-none focus:border-gray-300 leading-normal md:shadow-none md:bg-gray-200 lg:text-xl" placeholder="Search 55 million plates..." />
        <div className="absolute flex items-center top-0 right-0 bottom-0 pr-1 lg:pr-2">
          <button className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-700 text-white" onClick={() => callbacks.doSearch()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-white w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
          </button>
        </div>
      </div>
    }
  </RegistrationSearchForm>

export default HomePageSearchForm
