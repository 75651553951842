import React, {Fragment} from 'react'
import {ASSIGNMENT_FEE} from '../settings'

export const PlateCostBreakdown = ({plate, paid, includeAssignmentFee, showCards=true, showPlate=true, showBorder=true}) =>
  <>
    <div className={"bg-white rounded-lg " + (showBorder ? 'border border-gray-400 p-6' : '')}>
      <div className="">
        {
        showPlate &&
        <div className="inline-block">
          <div className="py-2 px-3 shadow-md rounded-md bg-plate">
            <span className="text-plate text-lg tracking-wider lg:text-2xl">{plate.text}</span>
          </div>
        </div>
        }
        <div className="mt-3 lg:mt-6">
          <div className="py-2">
            <div className="flex items-start">
              <div className="mr-2">
                <h4 className="font-normal text-gray-700">Registration</h4>
              </div>
              <div className="ml-auto">
                <span className="text-gray-700">&pound;{plate.price_ex_vat}</span>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex items-start">
              <div className="mr-2">
                <h4 className="font-normal text-gray-700">VAT</h4>
              </div>
              <div className="ml-auto">
                <span className="text-gray-700">&pound;{plate.vat}</span>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex items-start">
              <div className="mr-2">
                <h4 className="font-normal text-gray-700">DVLA Transfer Fee</h4>
              </div>
              <div className="ml-auto">
                <span className="text-gray-700">&pound;{plate.transfer_fee}</span>
              </div>
            </div>
          </div>
          { includeAssignmentFee && 
            <>
              <div className="border-t pt-2 mt-2"></div>
              <div className="py-2">
                <div className="flex items-start">
                  <div className="mr-2">
                    <h4 className="font-normal text-gray-700">Vehicle Assignment Fee</h4>
                  </div>
                  <div className="ml-auto">
                    <span className="text-gray-700">&pound;{ASSIGNMENT_FEE}</span>
                  </div>
                </div>
              </div>
            </>
          }
          <div className="border-t pt-2 mt-2"></div>
          <div className="pt-2">
            <div className="flex items-start">
              <div className="mr-2">
                <h4 className="font-medium">Total Cost Inc. Tax &amp; Fees</h4>
              </div>
              <div className="ml-auto">
                <span className="font-medium">&pound;{plate.price + (includeAssignmentFee ? ASSIGNMENT_FEE : 0)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showCards && <div className="hidden py-3 flex items-center lg:block">
          <img src="/img/cards.png" className="w-2/3 mx-auto"/>
        </div>
      }
    </div>
  </>

export default PlateCostBreakdown
