import React from 'react'


export const CheckoutHeader = ({stage}) =>
    <header className="z-50">
      <div className="flex flex-col space-y-2 items-center px-3 py-6 lg:p-12">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current text-gray-600 w-4 h-4" style={{'fill': 'none'}}>
          <rect className="a" x="3.75" y="9.75" width="16.5" height="13.5" rx="1.5" ry="1.5"></rect>
          <path className="a" d="M6.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75"></path>
          <circle className="a" cx="12" cy="16.5" r="2.25" fill="#fff"></circle>
        </svg>
        <h2 className="text-lg text-gray-700"><a href="#" className="text-blue-700 font-medium">EasyReg</a>&nbsp;Checkout</h2>
        <div className="flex flex-row items-center space-x-2">
          <div className="text-sm font-medium text-blue-700 lg:text-base">Contact</div>
          <div className="">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current w-2 h-2" style={{'fill': 'none'}}>
              <path className="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"></path>
            </svg>
          </div>
          <div className={(stage >= 1 ? "text-blue-700" :"text-gray-500")+" text-sm lg:text-base"}>Owner</div>
          <div className="">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current w-2 h-2" style={{'fill': 'none'}}>
              <path className="cls-1" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"></path>
            </svg>
          </div>
          <div className={(stage >= 2 ? "text-blue-700" :"text-gray-500")+" text-sm lg:text-base"}>Confirm &amp; Pay</div>
        </div>
      </div> 
    </header>

export default CheckoutHeader
