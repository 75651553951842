import React from 'react'
import {Helmet} from 'react-helmet'
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import {ContextWrapper} from './components/registration_context'

import Header from './components/header'
import Footer from './components/footer'

import Home from './components/home'
import SearchMain from './components/search_main'
import FavouritesPage from './components/favourites_page'
import PlateDetail from './components/plate_detail'
import Checkout from './components/new_checkout'
import {HowItWorksStandalone} from './components/checkout_how_it_works'
import CheckoutFailed from './components/checkout_failed'
import CheckoutComplete from './components/checkout_complete'
import TermsAndConditions from './components/terms_and_conditions'
import Privacy from './components/privacy_main'
import Popup from './components/popup'
import ScrollToTop from './components/scroll_to_top'

import './assets/main.css'

export const App = () => <>
  <Helmet>
    <title>EasyReg</title>
  </Helmet>
  <ContextWrapper>
    <BrowserRouter>
      <Popup/>
      <Switch>
        <Route path="/checkout/:plate/go">
          <Checkout/>
        </Route>
        <Route path="/">

          <Header/>

          <ScrollToTop>
            <Switch>
              <Route path="/search/:query/:page/" component={SearchMain}/>
              <Route path="/search/:query/:page" component={SearchMain}/>
              <Route path="/search/:query" component={SearchMain}/>

              <Route path="/detail/:plate">
                <PlateDetail/>
              </Route>

              <Route path="/checkout/complete/:orderId">
                <CheckoutComplete/>
              </Route>

              <Route path="/checkout/failed/:orderId">
                <CheckoutFailed/>
              </Route>

              <Route path="/checkout/:plate">
                <HowItWorksStandalone/>
              </Route>

              <Route path="/favourites">
                <FavouritesPage/>
              </Route>

              <Route path="/terms">
                <TermsAndConditions/>
              </Route>

              <Route path="/privacy">
                <Privacy/>
              </Route>

              <Route path="/">
                <Home/>
              </Route>
            </Switch>
          </ScrollToTop>

          <Footer/>
        </Route>
      </Switch>
    </BrowserRouter>

  </ContextWrapper>
</>

export default App
