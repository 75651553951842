import React, {Fragment} from 'react'
import HomePageSearchForm from './home_page_search_form'
import Unimplemented from './unimplemented'

export const Home = () => <Fragment>
  <div className="hero-image bg-cover lg:bg-gray-200">
    <div className="max-w-screen-xl mx-auto pb-40 lg:grid lg:grid-cols-8 lg:gap-24 lg:px-6 lg:py-24">
      <div className="bg-gradient-to-b from-gray-900 to-transparent px-4 py-6 space-y-2 text-center lg:from-transparent lg:text-left lg:rounded-lg lg:shadow-lg lg:px-8 lg:py-8 lg:space-y-8 lg:col-span-4 lg:bg-white">
        <h1 className="text-3xl text-white leading-tight lg:text-4xl lg:text-gray-900">Private number plates, <br/><span className="font-medium lg:text-blue-700">made easy.</span></h1>
        <HomePageSearchForm/>
      </div>
    </div>
  </div>

  <main className="space-y-12 lg:space-y-16">
    {/* Testimonial */}
    <div className="max-w-screen-xl px-4 pt-12 mx-auto lg:pt-16 lg:px-6">
      <div className="grid gap-6 lg:grid-cols-8 lg:gap-12">
        <div className="flex flex-col space-y-2 justify-center lg:col-span-4">
          <h2 className="text-2xl font-medium lg:text-3xl">Fast, affordable, easy.</h2>
          <p className="text-gray-700 lg:text-xl">Buying a personalised registration has never been so easy. We offer over 50 million registrations with an unmatched search & transfer service. Start your search online or call one of our professional transfer agents today.</p>
        </div>
        <div className="rounded-lg border shadow-lg bg-white px-6 py-6 lg:col-span-4">
          <div className="divide-y space-y-6">
            <div className="space-y-3">
              <div className="flex space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4 fill-current stroke-current text-yellow-400 lg:h-5 lg:w-5">
                  <path d="M12.729,1.2l3.346,6.629,6.44.638a.805.805,0,0,1,.5,1.374l-5.3,5.253,1.965,7.138a.813.813,0,0,1-1.151.935L12,19.934,5.48,23.163a.813.813,0,0,1-1.151-.935L6.294,15.09.99,9.837a.805.805,0,0,1,.5-1.374l6.44-.638L11.271,1.2A.819.819,0,0,1,12.729,1.2Z"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4 fill-current stroke-current text-yellow-400 lg:h-5 lg:w-5">
                  <path d="M12.729,1.2l3.346,6.629,6.44.638a.805.805,0,0,1,.5,1.374l-5.3,5.253,1.965,7.138a.813.813,0,0,1-1.151.935L12,19.934,5.48,23.163a.813.813,0,0,1-1.151-.935L6.294,15.09.99,9.837a.805.805,0,0,1,.5-1.374l6.44-.638L11.271,1.2A.819.819,0,0,1,12.729,1.2Z"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4 fill-current stroke-current text-yellow-400 lg:h-5 lg:w-5">
                  <path d="M12.729,1.2l3.346,6.629,6.44.638a.805.805,0,0,1,.5,1.374l-5.3,5.253,1.965,7.138a.813.813,0,0,1-1.151.935L12,19.934,5.48,23.163a.813.813,0,0,1-1.151-.935L6.294,15.09.99,9.837a.805.805,0,0,1,.5-1.374l6.44-.638L11.271,1.2A.819.819,0,0,1,12.729,1.2Z"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4 fill-current stroke-current text-yellow-400 lg:h-5 lg:w-5">
                  <path d="M12.729,1.2l3.346,6.629,6.44.638a.805.805,0,0,1,.5,1.374l-5.3,5.253,1.965,7.138a.813.813,0,0,1-1.151.935L12,19.934,5.48,23.163a.813.813,0,0,1-1.151-.935L6.294,15.09.99,9.837a.805.805,0,0,1,.5-1.374l6.44-.638L11.271,1.2A.819.819,0,0,1,12.729,1.2Z"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4 fill-current stroke-current text-yellow-400 lg:h-5 lg:w-5">
                  <path d="M12.729,1.2l3.346,6.629,6.44.638a.805.805,0,0,1,.5,1.374l-5.3,5.253,1.965,7.138a.813.813,0,0,1-1.151.935L12,19.934,5.48,23.163a.813.813,0,0,1-1.151-.935L6.294,15.09.99,9.837a.805.805,0,0,1,.5-1.374l6.44-.638L11.271,1.2A.819.819,0,0,1,12.729,1.2Z"></path>
                </svg>

              </div>
              <div className="text-gray-700 lg:text-xl">Third time buying from these guys. Helpful, fast. Can't recommend enough!</div>
            </div>
            <div className="flex pt-6">
              <div className="">
                <div className="font-medium lg:text-lg">LGV Training Company</div>
                <div className="text-sm text-gray-600 lg:text-base">Transferred in 4 days</div>
              </div>
              <div className="ml-auto">
                <div className="flex justify-center w-40 py-2 px-3 shadow-md rounded-md bg-plate">
                  <span className="text-plate text-xl tracking-wider">T123 HGV</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* How it works */}
    <div className="max-w-screen-xl border-t pt-12 px-4 mx-auto space-y-12 lg:pt-16 lg:px-6">
      <div className="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-6 lg:gap-12">
        <div className="lg:col-start-2 lg:col-span-4">
          <div className="space-y-2 lg:text-center">
            <h2 className="text-2xl font-medium lg:text-3xl">Everything taken care of.</h2>
            <p className="text-gray-700 lg:text-xl">Find a registration you love, and leave the rest to us.</p>
          </div>
        </div>
        <div className="space-y-8 lg:col-start-1 lg:col-span-6 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-12">
          <div className="col-span-1 flex space-x-4 lg:flex-col lg:items-center lg:space-x-0 lg:space-y-6">
            <div className="relative flex flex-shrink-0 items-center justify-center w-8 h-8">
              <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8 h-8">
                <ellipse cx="18" cy="18.0996" rx="6.75" ry="6.75" className="fill-current text-yellow-400" />
                <path d="M22.5 26.7246V30.0996C22.5 30.6963 22.2629 31.2686 21.841 31.6906C21.419 32.1126 20.8467 32.3496 20.25 32.3496H15.75C15.1533 32.3496 14.581 32.1126 14.159 31.6906C13.7371 31.2686 13.5 30.6963 13.5 30.0996V26.7246" className="stroke-current text-gray-700" />
                <path d="M18 1.97461V4.22461" className="stroke-current text-gray-700" />
                <path d="M2.25 17.7246H4.5" className="stroke-current text-gray-700" />
                <path d="M5.625 6.47461L7.275 8.06011" className="stroke-current text-gray-700" />
                <path d="M33.75 17.7246H31.5" className="stroke-current text-gray-700" />
                <path d="M30.3749 6.47461L28.7249 8.06011" className="stroke-current text-gray-700" />
                <path d="M13.3726 26.7246H22.6276C24.2989 25.895 25.7018 24.6099 26.6745 23.0176C27.6472 21.4252 28.15 19.5904 28.1251 17.7246C28.0777 15.0541 26.9957 12.5062 25.1071 10.6176C23.2185 8.72894 20.6706 7.64699 18.0001 7.59961C15.3285 7.64703 12.7798 8.72981 10.891 10.6197C9.00219 12.5096 7.92092 15.0591 7.87507 17.7306C7.85127 19.5954 8.35466 21.4289 9.32726 23.0201C10.2999 24.6113 11.7021 25.8954 13.3726 26.7246V26.7246Z" className="stroke-current text-gray-700" />
                <path d="M18 32.3496V35.7246" className="stroke-current text-gray-700" />
                <path d="M18 21.0996V26.7246" className="stroke-current text-gray-700" />
                <path d="M14.625 17.7246L18 21.0996L21.375 17.7246" className="stroke-current text-gray-700" />
              </svg>
            </div>
            <div className="lg:text-center">
              <div className="uppercase text-xs leading-wide text-gray-600">Step 1</div>
              <h3 className="text-lg font-medium lg:text-2xl">Find</h3>
              <div className="text-gray-700">Search 55 millions plates instantly, with smart recommendations you won't get anywhere else.</div>
            </div>
          </div>
          <div className="col-span-1 flex space-x-4 lg:flex-col lg:items-center lg:space-x-0 lg:space-y-6">
            <div className="relative flex flex-shrink-0 items-center justify-center w-8 h-8">
              <svg viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8 h-8">
                <rect x="3.37109" y="8.3208" width="12" height="22" rx="2" transform="rotate(-45 3.37109 8.3208)" className="fill-current text-yellow-400" />
                <path d="M9 29.537H23.8815C24.413 29.5371 24.9274 29.349 25.3334 29.006C25.7395 28.6631 26.0111 28.1875 26.1 27.6635L30.0555 4.4135C30.1448 3.88977 30.4165 3.41452 30.8225 3.0719C31.2285 2.72928 31.7427 2.54139 32.274 2.5415H33.75" className="stroke-current text-gray-700" />
                <path d="M11.8125 32.9121C11.9238 32.9121 12.0325 32.9451 12.125 33.0069C12.2175 33.0687 12.2896 33.1566 12.3322 33.2593C12.3748 33.3621 12.3859 33.4752 12.3642 33.5843C12.3425 33.6935 12.2889 33.7937 12.2102 33.8724C12.1316 33.951 12.0314 34.0046 11.9222 34.0263C11.8131 34.048 11.7 34.0369 11.5972 33.9943C11.4945 33.9517 11.4066 33.8796 11.3448 33.7871C11.283 33.6946 11.25 33.5859 11.25 33.4746C11.25 33.3254 11.3093 33.1824 11.4148 33.0769C11.5202 32.9714 11.6633 32.9121 11.8125 32.9121Z" className="stroke-current text-gray-700" />
                <path d="M23.0625 32.9121C23.1738 32.9121 23.2825 32.9451 23.375 33.0069C23.4675 33.0687 23.5396 33.1566 23.5822 33.2593C23.6248 33.3621 23.6359 33.4752 23.6142 33.5843C23.5925 33.6935 23.5389 33.7937 23.4602 33.8724C23.3816 33.951 23.2814 34.0046 23.1722 34.0263C23.0631 34.048 22.95 34.0369 22.8472 33.9943C22.7445 33.9517 22.6566 33.8796 22.5948 33.7871C22.533 33.6946 22.5 33.5859 22.5 33.4746C22.5 33.3254 22.5593 33.1824 22.6648 33.0769C22.7702 32.9714 22.9133 32.9121 23.0625 32.9121" className="stroke-current text-gray-700" />
                <path d="M26.9294 22.7872H8.82289C7.81951 22.7871 6.84495 22.4517 6.05408 21.8342C5.2632 21.2167 4.70138 20.3526 4.45789 19.3792L2.28289 10.6793C2.24139 10.5133 2.23826 10.3401 2.27375 10.1728C2.30924 10.0055 2.3824 9.84851 2.48768 9.71372C2.59296 9.57894 2.72759 9.46994 2.88133 9.395C3.03507 9.32006 3.20386 9.28115 3.37489 9.28125H29.2259" className="stroke-current text-gray-700" />
              </svg>
            </div>
            <div className="lg:text-center">
              <div className="uppercase text-xs leading-wide text-gray-600">Step 2</div>
              <h3 className="text-lg font-medium lg:text-2xl">Pay</h3>
              <div className="text-gray-700">Pay easily, quickly and securely online or call us to complete your purchase.</div>
            </div>
          </div>
          <div className="col-span-1 flex space-x-4 lg:flex-col lg:items-center lg:space-x-0 lg:space-y-6">
            <div className="relative flex flex-shrink-0 items-center justify-center w-8 h-8">
              <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8 h-8">
                <path d="M27.1875 22.8125C27.6848 22.8125 28.1617 22.615 28.5133 22.2633C28.865 21.9117 29.0625 21.4348 29.0625 20.9375V15.3125C29.0624 14.7128 28.8241 14.1377 28.4 13.7137L25.3125 10.625H4.6875L1.6 13.7125C1.38986 13.9226 1.22319 14.172 1.10952 14.4466C0.99584 14.7211 0.937387 15.0154 0.9375 15.3125V20.9375C0.9375 21.4348 1.13504 21.9117 1.48667 22.2633C1.83831 22.615 2.31522 22.8125 2.8125 22.8125H27.1875Z" className="stroke-current text-gray-700" />
                <path d="M4.6875 10.625L6.84125 4.875C7.03565 4.36034 7.38228 3.91715 7.83496 3.60449C8.28763 3.29184 8.82485 3.12458 9.375 3.125H20.625C21.1751 3.12475 21.7122 3.29209 22.1649 3.60472C22.6175 3.91734 22.9642 4.36044 23.1588 4.875L25.3125 10.625H4.6875Z" className="stroke-current text-gray-700" />
                <path d="M4.6875 10.6262L2.8125 8.75122H0.9375" className="stroke-current text-gray-700" />
                <path d="M25.3125 10.6262L27.1875 8.75122H29.0625" className="stroke-current text-gray-700" />
                <path d="M2.8125 22.8125V24.6875C2.8125 25.1848 3.01004 25.6617 3.36167 26.0133C3.71331 26.365 4.19022 26.5625 4.6875 26.5625C5.18478 26.5625 5.66169 26.365 6.01333 26.0133C6.36496 25.6617 6.5625 25.1848 6.5625 24.6875V22.8125H2.8125Z" className="stroke-current text-gray-700" />
                <path d="M27.1875 22.8125V24.6875C27.1875 25.1848 26.99 25.6617 26.6383 26.0133C26.2867 26.365 25.8098 26.5625 25.3125 26.5625C24.8152 26.5625 24.3383 26.365 23.9867 26.0133C23.635 25.6617 23.4375 25.1848 23.4375 24.6875V22.8125H27.1875Z" className="stroke-current text-gray-700" />
                <path d="M28.8586 14.375H23.4374L21.1711 18.1525C21.0045 18.43 20.7689 18.6597 20.4872 18.8192C20.2055 18.9787 19.8873 19.0625 19.5636 19.0625H10.4374C10.1131 19.0628 9.79434 18.979 9.51215 18.8193C9.22997 18.6595 8.99403 18.4294 8.82736 18.1512L6.56236 14.375H1.14111" className="fill-current text-yellow-400" />
                <path d="M28.8586 14.375H23.4374L21.1711 18.1525C21.0045 18.43 20.7689 18.6597 20.4872 18.8192C20.2055 18.9787 19.8873 19.0625 19.5636 19.0625H10.4374C10.1131 19.0628 9.79434 18.979 9.51215 18.8193C9.22997 18.6595 8.99403 18.4294 8.82736 18.1512L6.56236 14.375H1.14111" className="stroke-current text-gray-700" />
                <path d="M21.1714 18.125H29.0626" className="stroke-current text-gray-700" />
                <path d="M8.82875 18.125H0.9375" className="stroke-current text-gray-700" />
                <path d="M23.4375 14.375H6.5625" className="stroke-current text-gray-700" />
              </svg>
            </div>
            <div className="lg:text-center">
              <div className="uppercase text-xs leading-wide text-gray-600">Step 3</div>
              <h3 className="text-lg font-medium lg:text-2xl">Transfer</h3>
              <div className="text-gray-700">A transfer expert will begin transferring your registration to you immediately.</div>
            </div>
          </div>
          <div className="col-span-1 flex space-x-4 lg:flex-col lg:items-center lg:space-x-0 lg:space-y-6">
            <div className="relative flex flex-shrink-0 items-center justify-center w-8 h-8">
              <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-8 h-8">
                <path d="M12.375 8.27344L8.325 13.6734L5.625 10.9734" className="stroke-current text-gray-700" />
                <path d="M12.375 21.3232L8.325 26.7232L5.625 24.0232" className="stroke-current text-gray-700" />
                <path d="M14.625 13.2231H19.125" className="stroke-current text-gray-700" />
                <path d="M14.625 26.7231H19.125" className="stroke-current text-gray-700" />
                <path d="M19.125 1.97314H3.375C2.77826 1.97314 2.20597 2.2102 1.78401 2.63215C1.36205 3.05411 1.125 3.62641 1.125 4.22314V33.4731C1.125 34.0699 1.36205 34.6422 1.78401 35.0641C2.20597 35.4861 2.77826 35.7231 3.375 35.7231H21.375" className="stroke-current text-gray-700" />
                <path d="M23.625 28.9731C23.625 29.5698 23.8621 30.1421 24.284 30.5641C24.706 30.9861 25.2783 31.2231 25.875 31.2231H28.125C28.7217 31.2231 29.294 30.9861 29.716 30.5641C30.1379 30.1421 30.375 29.5698 30.375 28.9731V11.0996H23.625V28.9731Z" className="stroke-current text-gray-700" />
                <path d="M30.375 4.22314H32.625C33.2217 4.22314 33.794 4.4602 34.216 4.88215C34.6379 5.30411 34.875 5.87641 34.875 6.47314V16.5996" className="stroke-current text-gray-700" />
                <path d="M30.375 12.0996V4.22314C30.375 3.62641 30.1379 3.05411 29.716 2.63215C29.294 2.2102 28.7217 1.97314 28.125 1.97314H25.875C25.2783 1.97314 24.706 2.2102 24.284 2.63215C23.8621 3.05411 23.625 3.62641 23.625 4.22314V12.0996" className="fill-current text-yellow-400" />
                <path d="M30.375 12.0996V4.22314C30.375 3.62641 30.1379 3.05411 29.716 2.63215C29.294 2.2102 28.7217 1.97314 28.125 1.97314H25.875C25.2783 1.97314 24.706 2.2102 24.284 2.63215C23.8621 3.05411 23.625 3.62641 23.625 4.22314V12.0996" className="stroke-current text-gray-700" />
                <path d="M27 31.2231V35.7231" className="stroke-current text-gray-700" />
              </svg>
            </div>
            <div className="lg:text-center">
              <div className="uppercase text-xs leading-wide text-gray-600">Step 4</div>
              <h3 className="text-lg font-medium lg:text-2xl">Fit</h3>
              <div className="text-gray-700">Follow our simple instructions to assign it your vehicle.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="max-w-screen-xl border-t py-12 mx-auto lg:pt-16 lg:px-6">
        <div className="px-4 lg:px-0 lg:grid lg:grid-cols-6 lg:gap-6">
          <div className="pb-6 col-start-2 col-span-4 space-y-8">
            <div className="space-y-2 lg:text-center">
              <h2 className="text-2xl font-medium lg:text-3xl">Inspiration for everyone.</h2>
              <p className="text-gray-700 lg:text-xl">Not sure what you want? Let us help you find the perfect registration for you, with dozens of fun, cheeky and clever ideas.</p>
            </div>
          </div>
        </div>
        <div className="px-4 pb-12 flex flex-row flex-wrap justify-center">
          <a href="/search/afc" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Arsenal FC</div>
          </a>
          <a href="/search/boss" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Boss</div>
          </a>
          <a href="/search/mrs" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Mrs</div>
          </a>
          <a href="/search/miss" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Miss</div>
          </a>
          <a href="/search/gas" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Gas</div>
          </a>
          <a href="/search/pipe" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Plumbing</div>
          </a>
          <a href="/search/cfc" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Chelsea FC</div>
          </a>
          <a href="/search/joe" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Joe</div>
          </a>
          <a href="/search/dave" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Dave</div>
          </a>
          <a href="/search/tesla" className="transform scale-100 flex items-center py-3 px-6 m-2 rounded-full bg-white border-gray-200 shadow-lg space-x-2 hover:shadow-xl hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
            <div className="text-lg lg:text-xl font-medium">Tesla</div>
          </a>
        </div>
      </div>
  </main>
</Fragment>

export default Home
