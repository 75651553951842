import React, { createContext } from 'react'
import { RegistrationStore } from '../store/registration_store'

export const RegistrationContext = createContext()

const store = new RegistrationStore

export const ContextWrapper = ({children}) =>
  <RegistrationContext.Provider value={store}>
    {children}
  </RegistrationContext.Provider>
