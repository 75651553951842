import {Link} from 'react-router-dom'
import StripeCheckoutButton from './components/stripe_checkout_button'
import globalpay_checkout from './globalpay'
import {PAYMENT_PROVIDER} from './settings'

export const setup = (factory) => {
  switch (PAYMENT_PROVIDER) {

    case 'stripe':
      factory.register('DetailLink', ({plate}) => [
        StripeCheckoutButton, {
          plate: plate.text,
          price: plate.price_inc_vat,
        } ])
      factory.register('CheckoutButton', ({plate}) => [
        StripeCheckoutButton, {
          plate: plate.text,
          price: plate.price_inc_vat,
        } ])
      break

    case 'globalpay':
      factory.register('DetailLink', ({plate}) => [Link, { to: '/checkout/'+plate.id} ])
      factory.register('CheckoutFunction', () => globalpay_checkout)
      break

    default:
      throw new Error(
        "Unrecognised payment provider " + PAYMENT_PROVIDER
      )

  }

}

export default setup
