import React, { useContext, Fragment } from 'react'
import Filter from './filter'
import { RegistrationContext } from './registration_context'
import { observer } from 'mobx-react'

export const SearchFilters = () => {
  const store = useContext(RegistrationContext)
  return <div className="flex flex-wrap space-x-2">
              <Filter
                    name="max_price"
                    prompt='Filter by Max Price'
                    options={{
                      '250': 'Under £250',
                      '500': 'Under £500',
                      '750': 'Under £750',
                    }}
                    selected={store.getFilterSelection('max_price')}
                    onChange={(val) => store.setFilterSelection('max_price',val)}
              />
          <div className="relative flex-shrink-0" style={{'display':'none'}}>
            <div className="border rounded-full px-4 py-2 text-sm cursor-pointer lg:text-base text-gray-700 hover:border-gray-500 transition duration-300">Format</div>
          </div>
          <div className="relative flex-shrink-0" style={{'display':'none'}}>
            <div className="rounded-full bg-blue-700 py-2 px-2 inline-flex items-center cursor-pointer hover:bg-blue-800 transition duration-300">
              <div className="text-sm lg:text-base text-white tracking-wide ml-2">Newer than 1996</div>
              <div className="flex items-center px-2 py-2 text-white rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="text-white stroke-current w-2 h-2" style={{fill: 'none'}}>
                  <line className="a" x1="0.75" y1="23.249" x2="23.25" y2="0.749"/>
                  <line className="a" x1="23.25" y1="23.249" x2="0.75" y2="0.749"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
}

export default observer(SearchFilters)
