import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { noop } from '../helpers'

import React, { useContext } from 'react'

const FormContext = React.createContext(null)


export const Form = ({validate, onSubmit, children, initialValues, isSubmitting, onChange=noop}) =>
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} enableReinitialize={true}>
      { (formik) => <>
        <FormListener formik={formik} onChange={onChange}/>
        <FormContext.Provider value={formik}>
          <FormikForm>
            {children}
          </FormikForm>
        </FormContext.Provider>
      </> }
    </Formik>

export const FormListener = ({onChange=noop, formik}) => {
  const {values} = formik

  React.useEffect(() => {
    onChange({values, formik})
  }, [values])
  return <></>
}

export const ScrollToTop = () => {
  const {isSubmitting} = useContext(FormContext)
  const [ wasSubmitting, setSubmitting ] = React.useState(false)

  if (isSubmitting != wasSubmitting) {
    if (wasSubmitting) {
      window.scrollTo(0,0)
    }
    setSubmitting( isSubmitting )
  }

  return <></>
}

export const Field = ({validate, name, children, component}) => {
  const {errors, touched, values} = useContext(FormContext)
  
  const doValidations = (value) => {
    if (!validate) {
      return 
    }
    if (validate.constructor == Array) {
      return validate.reduce(
        (error, validator) => error ? error : validator(value),
        null
      )
    } else if(validate) {
      return validate(value)
    }
  }

  return <>
    <FormikField name={name} validate={doValidations} debug={[errors,touched]}>
      { 
        ({field}) => children({
          field: field,
          errors: (errors[name] && touched[name]) ? errors[name] : null,
          value: values ? values[name] : null,
        })
      }
    </FormikField>
    { ( errors[name] && touched[name] ) &&
      <div className='error'>{errors[name]}</div>
    }
  </>
}


export const Radio = (params) => <FormikField type="radio" {...params}/>
