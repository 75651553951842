import React, {useContext} from 'react'
import { observer } from 'mobx-react'
import {Link, useHistory} from 'react-router-dom'

import { RegistrationContext } from './registration_context'
import { FavouriteButton } from './favourite_button'

export const RegistrationList = ({registrations}) => {
  const store = useContext(RegistrationContext)
  const history = useHistory()

  const viewRegistration = (registration) => {
    history.push("/detail/"+registration.id)
  }

  const selectResult = (result) => console.log("Selected Result ",result)
  return <ol className="border-t border-b divide-y lg:mx-6">
    {
      registrations.map((registration) => 
        <li key={registration.id} className="flex items-center py-3 px-3 hover:bg-gray-100 cursor-pointer lg:py-4 lg:pr-4" onClick={() => viewRegistration(registration)}>
          <Link to={"/detail/"+registration.id}>
            <div className="flex items-center space-x-4">
              <div className="flex justify-center w-40 py-2 px-3 shadow-md rounded-md bg-plate">
                <span className="text-plate text-xl tracking-wider lg:text-2xl">{registration.text}</span>
              </div>
              <div className="text-xl lg:text-2xl text-gray-700">&pound;{registration.price}</div>
            </div>
          </Link>
          <div className="ml-auto space-x-2">
            <FavouriteButton plate={registration.id} selected={store.isFavourited(registration.id)}/>
          </div>
        </li>
      ) 
    }
    </ol>
}

export default observer(RegistrationList)
