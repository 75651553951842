import React, { useContext, useCallback, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import debounce from 'lodash.debounce'

import { RegistrationContext } from './registration_context'

export const RegistrationSearchForm = ({children, onSearch, ref, autoSubmit, showOnHome}) => {
  const store = useContext(RegistrationContext)
  const location = useLocation()
  const history = useHistory()
  const [searchString, setSearchString] = useState(store.filters.q)


  const apiSearch = useCallback(
    debounce(
      (string) => {
        const search = store.search(string)
        if (onSearch) {
          onSearch(string)
        }
        history.push('/search/'+string+'/1/')
      },
      1000,
      {
        'leading': true,
        'trailing': true,
      }
    ),
    []
  )


  // Hide the header search on the homepage
  if ((location.pathname=='/') && (!showOnHome)) {
    return <></>
  }

  const search = (query) => {
    setSearchString(query)
    if (autoSubmit) {
      apiSearch(query)
    }
  }
  const doSearch = () => {
      apiSearch(searchString)
  }

  const handleKeyDown = (ev) => {
    if (ev.key=='Enter') {
      doSearch()
    }
  }

  const inputProps = {
    onChange: (ev) => search(ev.target.value),
    value: searchString,
    onKeyDown: handleKeyDown
  }
  const extraProps = {
    clearSearch: () => search(""),
    doSearch: doSearch
  }


  if (children) {
    return children(inputProps, extraProps)
  } else {
    return <>
        <div className="sm:search flex flex-grow relative max-w-md my-3 lg:mx-6">
        <input ref={ref} name='skip_form_filler' {...inputProps} className="flex flex-grow bg-gray-200 rounded-full py-2 px-4 pl-10 border border-gray-200 focus:outline-none focus:border-gray-300 appearance-none leading-normal" placeholder="Start typing..."/>
        <div className="absolute flex items-center top-0 left-0 bottom-0" onClick={doSearch}>
          <div className="flex items-center justify-center w-10 h-10 pl-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current stroke-2 text-gray-500 w-4 h-4" fill="none">
              <circle className="a" cx="9.813" cy="9.812" r="9.063" transform="translate(-3.056 4.62) rotate(-23.025)"></circle>
              <line className="a" x1="16.221" y1="16.22" x2="23.25" y2="23.25"></line>
            </svg>
          </div>
        </div>
        { searchString && 
            <div className="absolute flex items-center top-0 right-0 bottom-0 mr-2" onClick={extraProps.clearSearch}>
              <div className="flex items-center px-2 py-2 text-white bg-gray-300 rounded-full ml-auto">
                <svg xmlns="w3.org/2000/svg" viewBox="0 0 24 24" className="text-gray-700 stroke-current w-2 h-2" style={{"fill": "none"}}>
                  <line className="a" x1="0.75" y1="23.249" x2="23.25" y2="0.749"></line>
                  <line className="a" x1="23.25" y1="23.249" x2="0.75" y2="0.749"></line>
                </svg>
              </div>
            </div>
        }
        </div>
      </>
  }
}

export default RegistrationSearchForm
