import React, {useContext} from 'react'
import {useParams} from 'react-router-dom'
import {RegistrationContext} from '../registration_context'

export const hasOrderDetail = (Component) => 
  ({order, ...props}) => {
    const params = useParams()
    if (!order) {
      order = params.orderId
    }
    const store = useContext(RegistrationContext)
    const orderDetail = store.getOrderDetail(order)

    if (orderDetail) {
      return <Component {...orderDetail} {...props}/>
    } else {
      return ''
    }
  }

export default hasOrderDetail
