import React from 'react'
import {Link} from 'react-router-dom'
import Unimplemented from './unimplemented'

export const Footer = () => 
  <footer className="bg-blue-900">
      <div className="max-w-screen-xl mx-auto py-6">
        <div className="text-lg font-medium text-white px-6 py-6">EasyReg</div>
        <Unimplemented>
        <div className="flex flex-row overflow-x-scroll lg:grid lg:grid-cols-5 px-6 py-6">
          <div className="flex flex-col flex-shrink-0 space-y-3 w-3/5">
            <h4 className="font-medium text-white">Names</h4>
            <ul className="space-y-3 text-sm">
              <li><a className="text-blue-200">Dave</a></li>
              <li><a className="text-blue-200">Mohammed</a></li>
              <li><a className="text-blue-200">John</a></li>
              <li><a className="text-blue-200">Susan</a></li>
              <li><a className="text-blue-200">Tim</a></li>
            </ul>
          </div>
          <div className="flex flex-col flex-shrink-0 space-y-3 w-3/5">
            <h4 className="font-medium text-white">Trades</h4>
            <ul className="space-y-3 text-sm">
              <li><a className="text-blue-200">Gas</a></li>
              <li><a className="text-blue-200">Electricity</a></li>
              <li><a className="text-blue-200">Plumbing</a></li>
              <li><a className="text-blue-200">Susan</a></li>
              <li><a className="text-blue-200">Tim</a></li>
            </ul>
          </div>
          <div className="flex flex-col flex-shrink-0 space-y-3 w-3/5">
            <h4 className="font-medium text-white">Sports Teams</h4>
            <ul className="space-y-3 text-sm">
              <li><a className="text-blue-200">Arsenal</a></li>
              <li><a className="text-blue-200">Manchester Utd</a></li>
              <li><a className="text-blue-200">Chelsea</a></li>
              <li><a className="text-blue-200">Tottenham Hotspurs</a></li>
              <li><a className="text-blue-200">Liverpool</a></li>
            </ul>
          </div>
          <div className="flex flex-col flex-shrink-0 space-y-3 w-3/5">
            <h4 className="font-medium text-white">Titles</h4>
            <ul className="space-y-3 text-sm">
              <li><a className="text-blue-200">Dave</a></li>
              <li><a className="text-blue-200">Mohammed</a></li>
              <li><a className="text-blue-200">John</a></li>
              <li><a className="text-blue-200">Susan</a></li>
              <li><a className="text-blue-200">Tim</a></li>
            </ul>
          </div>
          <div className="flex flex-col flex-shrink-0 space-y-3 w-3/5">
            <h4 className="font-medium text-white">Titles</h4>
            <ul className="space-y-3 text-sm">
              <li><a className="text-blue-200">Dave</a></li>
              <li><a className="text-blue-200">Mohammed</a></li>
              <li><a className="text-blue-200">John</a></li>
              <li><a className="text-blue-200">Susan</a></li>
              <li><a className="text-blue-200">Tim</a></li>
            </ul>
          </div>
        </div>
        </Unimplemented>
        <div className="flex flex-col px-6 py-6 space-y-3 lg:space-y-0 lg:flex-row">
          <ol className="flex space-x-4">
            <li><Link to="/terms" className="text-sm text-blue-200 hover:underline">Terms &amp; conditions</Link></li>
            <li><Link to="/privacy" className="text-sm text-blue-200 hover:underline">Privacy Policy</Link></li>
          </ol>
          <div className="text-sm text-gray-500 lg:ml-auto">&copy; 2024 Easy Reg Ltd. All rights reserved.</div>
        </div>
      </div>
  </footer>

export default Footer
