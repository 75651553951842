import React from 'react'

export const Currency = ({amount}) => {
  const amount_pounds = parseInt(amount)/100
  return <>
    &pound;{amount_pounds}
  </>
}

export default Currency
