import React, {useContext, useEffect, useState} from 'react'
import { observer } from 'mobx-react'
import { RegistrationContext } from './registration_context'
import { RegistrationList } from './registration_list'

export const FavouritesPage = () => {
  const store = useContext(RegistrationContext)
  const favourites = store.favourites
  const hasFavourites = favourites.length>0


  return <main className="max-w-screen-xl mx-auto">
    <div id="#content" className="flex flex-col lg:grid lg:grid-cols-5 lg:gap-24">

    <section className="w-full lg:pb-12 lg:col-span-3">
      <div className="px-3 py-6 space-y-2 lg:px-6 lg:py-10">
        <div>
          <h1 className="text-2xl font-medium lg:text-4xl"><span className="font-bold">Favourites</span></h1>
          {(!hasFavourites) &&
            <div className="text-xl text-gray-500">
              No favourites yet. Tap the 
              <span className="inline-block">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="stroke-current fill-current w-4 h-4" style={{"fill": "none"}}>
                  <path d="M12,21.844l-9.588-10A5.672,5.672,0,0,1,1.349,5.293h0a5.673,5.673,0,0,1,9.085-1.474L12,5.384l1.566-1.565a5.673,5.673,0,0,1,9.085,1.474h0a5.673,5.673,0,0,1-1.062,6.548Z"/>
                </svg>
              </span>
              button to add a registration here.
            </div>
          }
        </div>
      </div>
      {hasFavourites && <RegistrationList registrations={favourites}/> }
    </section>
    <aside className="col-span-2">
      <div className="lg:sticky top-0 py-12">
        <div className="bg-white space-y-6">
          <img src="people.png" className="pb-3"/>
          <div className="px-4 md:px-6">
              <h2 className="text-lg font-medium md:text-xl">Need a hand?</h2>
              <p className="text-gray-600 md:text-lg">Questions about the process? Stuck for ideas? Get in touch for instant help from an expert advisor.</p>
              <ol className="py-6 space-y-4">
                <li><a href="tel:+448081756125" className="font-medium text-blue-700 lg:text-lg">0808 175 6125</a></li>
                <li><a href="mailto:help@easy-reg.co.uk" className="font-medium text-blue-700 lg:text-lg">help@easy-reg.co.uk</a></li>
              </ol>
          </div>
        </div>
      </div>
    </aside>
    </div>
  </main>
}

export default observer(FavouritesPage)

