import React from 'react'
import {useHistory} from 'react-router-dom'
import {observer} from 'mobx-react'
import {hasPlateDetail} from './hoc/has_plate_detail'

export const CheckoutHowItWorks = ({onStageCompleted}) => {
    return <main className="max-w-screen-xl mx-auto lg:px-6">
      <div id="#content" className="flex flex-col">
        <article className="px-3 py-12 lg:text-center">
          <div className="space-y-2">
              <h1 className="font-medium text-2xl lg:text-3xl">What Happens Next</h1>
          </div>
          <div className="py-6 space-y-6 lg:space-y-0 md:grid md:grid-cols-3 md:gap-12">
            <div className="flex flex-row-reverse md:flex-col md:items-center">
              <div className="flex-shrink-0 w-32 lg:w-1/2">
                <img src="/img/1_payment.svg"/>
              </div>
              <div className="flex flex-col md:items-center md:space-y-2">
                <div className="text-xs uppercase text-gray-600">Step 1</div>
                <h2 className="text-lg font-medium text-gray-800 lg:text-2xl">Complete Checkout</h2>
                <p className="text-sm text-gray-700 lg:text-lg">Tell us about you and your vehicle then pay immediately or in 3 easy installments.</p>
              </div>
            </div>
            <div className="flex flex-row-reverse md:flex-col md:items-center">
              <div className="flex-shrink-0 w-32 lg:w-1/2">
                <img src="/img/2_relax.svg"/>
              </div>
              <div className="flex flex-col md:items-center md:space-y-2">
                <div className="text-xs uppercase text-gray-600">Step 2</div>
                <h2 className="text-lg font-medium text-gray-800 lg:text-2xl">Relax</h2>
                <p className="text-sm text-gray-700 lg:text-lg">Our experts get to work. We aim to complete most transfers within 7 days.</p>
              </div>
            </div>
            <div className="flex flex-row-reverse md:flex-col md:items-center">
              <div className="flex-shrink-0 w-32 lg:w-1/2">
                <img src="/img/3_certificates.svg"/>
              </div>
              <div className="flex flex-col md:items-center md:space-y-2">
                <div className="text-xs uppercase text-gray-600">Step 3</div>
                <h2 className="text-lg font-medium text-gray-800 lg:text-2xl">Receive Your Certificates</h2>
                <p className="text-sm text-gray-700 lg:text-lg">Once your certificates arrive, you're good to fit your physical plates.</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center lg:pt-12">
            <button className="w-full bg-blue-700 py-3 px-4 rounded inline-flex justify-center items-center hover:bg-blue-800 transition duration-300 lg:py-4 lg:px-12 lg:w-auto" onClick={onStageCompleted}>
              <div className="text-white tracking-wide font-bold lg:text-lg">Proceed to Checkout</div>
            </button>
          </div>
        </article>
      </div>
    </main>
}

export const HowItWorksStandalone = observer(hasPlateDetail(
  ({plate}) => {
    const history = useHistory()
    const goToCheckout = () => {
      history.push('/checkout/'+plate.id+'/go')
    }

    return <CheckoutHowItWorks onStageCompleted={goToCheckout}/>
  }
))
export default observer(hasPlateDetail(CheckoutHowItWorks))
